import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  noScrollbar: {
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  radio: {
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      boxShadow: 'none',
      border: 'none'
    }
  },
  stepConnector: {
    marginBottom: '40px',
    '& .MuiStepConnector-line': {
      borderColor: '#D9D9D9',
      borderWidth: '3px'
    }
  },
  stepIconContainer: {
    paddingRight: 0
  },
  mobileNumber: {
    outline: 'none',
    marginTop: '40px',
    marginBottom: '4px',
    '& .MuiFilledInput-input': {
      backgroundColor: '#FFFFFF',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      borderRadius: '4px',
      boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
      marginBottom: '4px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    '& .Mui-focused': {
      border: 'none !important'
    }
  },
  addSecurityMethod: {
    color: '#016AD4',
    fontWeight: 500,
    alignSelf: 'flex-start',
    padding: '8px 12px',
    textTransform: 'none',
    marginTop: '18px'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  radioIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    border: '1.5px solid #00274D26',
    boxShadow: '0px 4px 4px 0px #00000026 inset',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  radioCheckedIcon: {
    marginRight: 0,
    borderRadius: '50%',
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },
  loadingIndicator: {
    color: '#FFFFFF'
  }
}))
