import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  chip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
    margin: '4px 8px 0 0'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  messageOverflow: {
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'visible',
    whiteSpace: 'normal'
  },
  tags: {
    borderRadius: '14px'
  },
  tagMod1: {
    color: '#FFFFFF',
    backgroundColor: '#0F89FF'
  },
  tagMod2: {
    color: '#00274D',
    backgroundColor: 'rgba(40, 77, 112, 0.15)'
  }
}))
