import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'

type QueryKey = [string, {userId: string}]

const userFetcher = async ({queryKey}) => {
  const [base, {userId}] = queryKey as QueryKey

  if (!userId) return

  const response = await api.get<User>(`/${base}/${userId}`)
  return response.data
}

export const useUser = (userId: string | null) =>
  useQuery(['users', {userId}], userFetcher, {
    refetchOnWindowFocus: true,
    retry: false,
    onError: (error) => {
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: (error as AxiosError).response?.status,
        component: 'useUser',
        endpoint: `/users/${userId}`
      })
    }
  })
