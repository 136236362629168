import {Content, DefaultFooter} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HeaderLogo} from './components/HeaderLogo'
import {ReactComponent as HeidelbergCementLogo} from './Heidelbergcement.svg'
export const ErrorInfo = () => {
  const {t} = useTranslation()
  return (
    <Shell
      compact={false}
      isResponsive
      boxed={false}
      onDark={false}
      showScrollToTop={false}
      stickyFooter={true}
      header={
        <>
          <HeaderLogo withoutBranding={true} isCustomerAdmin={false} />
          <Box borderLeft={'1px solid lightgrey'} ml={1} pl={1.7} pr={1}>
            <HeidelbergCementLogo />
          </Box>
        </>
      }
      footer={<DefaultFooter />}
    >
      <Content>
        <Box
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}
        >
          <Typography variant="h3">{t('errorboundary.error')}</Typography>
        </Box>
      </Content>
    </Shell>
  )
}
