import {Country} from '@hconnect/apiclient'
import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AutoSelectDropdown} from '../../../common/AutoSelectDropdown'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserLanguageInputProps = UserInformationProps & {
  selectedCountry?: Country
}

export const UserLanguageInput = ({
  readonlyMode,
  formMethods,
  selectedCountry,
  isLoading
}: UserLanguageInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const classes = useStyles()
  const values = formMethods.watch()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  const defaultLocaleHelperText = (() => {
    if (!values.country) {
      return t('userInformationForm.defaultLocaleHelperText')
    }
    if (!values.defaultLocale && formMethods.formState.isSubmitted && errors.defaultLocale) {
      return t('userInformationForm.defaultLocaleIsRequired')
    }
  })()

  return (
    <>
      {readonlyMode ? (
        <TextField
          label={t('userInformationForm.defaultLocale')}
          value={
            selectedCountry?.locales.find((locale) => locale.code === values.defaultLocale)?.name
          }
          data-test-id="userInformationForm-defaultLocale"
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      ) : (
        <Controller
          name="defaultLocale"
          control={formMethods.control}
          rules={{required: true}}
          render={({field}) => (
            <AutoSelectDropdown
              autoSelectSingleOption={true}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              inputRef={field.ref}
              className={classes.textField}
              FormHelperTextProps={{className: classes.helperText}}
              fullWidth
              data-test-id={`userInformationForm-${field.name}`}
              label={t('userInformationForm.defaultLocale')}
              helperText={defaultLocaleHelperText}
              disabled={!values.country}
              select={true}
              margin="normal"
              error={
                !values.defaultLocale && formMethods.formState.isSubmitted && !!errors.defaultLocale
              }
              style={{maxWidth: 250}}
              options={
                selectedCountry?.locales.map((locale) => ({
                  value: locale.code,
                  label: locale.name
                })) ?? []
              }
              setFieldValue={(_, value) =>
                formMethods.setValue(field.name, value, {shouldValidate: true})
              }
            />
          )}
        />
      )}
    </>
  )
}
