import {User, Company} from '@hconnect/apiclient'

export const generateEventTrackBodyFromUser = (user: User) => ({
  product: user.creationProduct,
  date: new Date().toISOString(),
  country: user.country,
  role: user.hasRoles
})

export const generateEventTrackBodyFromCompany = (company: Company) => ({
  name: company.name,
  date: new Date().toISOString(),
  country: company.country
})
