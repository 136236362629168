import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../../../../App.store'

export interface Subscription {
  id: string
  name: string
  description: string
}

type UseSubscriptionsQueryKey = ['companies', 'subscriptions']

const fetchSubscriptions =
  (): QueryFunction<Subscription[] | undefined, UseSubscriptionsQueryKey> =>
  async ({queryKey}) => {
    const [,] = queryKey
    const response = await api.get<Subscription[]>('digital-subscriptions/subscriptions')
    return response.data
  }

export const useSubscriptions = () => {
  return useQuery(['companies', 'subscriptions'], fetchSubscriptions(), {
    retry: false
  })
}
