import {Country} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'

const countriesConfigurationFetcher: QueryFunction<Country[]> = async ({queryKey}) => {
  const [base, endpoint] = queryKey

  const response = await api.get<Country[]>(`/${base}/${endpoint}`)
  return response.data
}

export const useCountriesConfiguration = () =>
  useQuery<Country[], AxiosError>(['configurations', 'countries'], countriesConfigurationFetcher, {
    retry: false,
    onError: (error) => {
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'useCountriesConfiguration',
        endpoint: '/configurations/countries'
      })
    }
  })
