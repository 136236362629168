import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  mainPageStyle: {
    padding: '26px 36px',
    marginTop: theme.spacing(2)
  },

  mainPageMobileStyle: {
    padding: '4px 8px',
    marginTop: theme.spacing(2)
  },
  formCard: {
    display: 'flex',
    width: '524px !important',
    padding: 'var(--Spacing-padding-canvas-card, 24px)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--Spacing-padding-card, 16px)'
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch'
  },

  formContent: {
    display: 'flex',
    width: '476px',
    paddingBottom: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px'
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch'
  },

  formFooterButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch'
  },

  title: {
    color: 'var(--Light-text-low-contrast, #54708C)',
    fontFeatureSettings: "'clig' off, 'liga' off",

    /* Card Title */
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px' /* 120% */
  },
}))
