import {Checkbox, FormControlLabel, Typography} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'

import {AppState} from '../../../App.reducer'
import {isInternalSelector} from '../selectors'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'

type TestUserInputProps = UserInformationProps & {
  isTester?: boolean
}

export const TestUserInput = ({
  readonlyMode,
  formMethods,
  isTester,
  isLoading
}: TestUserInputProps) => {
  const {pathname} = useLocation()
  const isInternal = useSelector((state: AppState) => isInternalSelector(state))
  const {t} = useTranslation()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <>
      {readonlyMode && isInternal && isTester && !pathname.includes('managePilot') && (
        <Typography data-test-id="test-user-flag" variant="body2" color="textPrimary" gutterBottom>
          {t('userInformationForm.thisIsATestUser')}
        </Typography>
      )}
      {!readonlyMode && isInternal && !pathname.includes('managePilot') && (
        <Controller
          name="isTester"
          control={formMethods.control}
          render={({field}) => (
            <FormControlLabel
              {...field}
              checked={field.value}
              data-test-id="label-test-user"
              control={<Checkbox value="isTester" />}
              label={t('userInformationForm.thisIsATestUser')}
            />
          )}
        />
      )}
    </>
  )
}
