import {Country, Market, Product, User} from '@hconnect/apiclient'
import i18next from 'i18next'

import {hasPassword} from '../../../pages/ManageUser/InviteUserButtons'
export interface BrandingForCountry {
  brandingId: string
  brandingName: string
}
export const getBrandingsForCountry = (
  availableCountries: Country[],
  countryId: string
): BrandingForCountry[] => {
  const countryConfiguration = availableCountries.find(
    (country) => country.countryCode.toLowerCase() === countryId.toLowerCase()
  )
  return countryConfiguration?.brandings ? countryConfiguration.brandings : []
}

export const getBrandingNameFromBrandingId = (
  availableCountries: Country[],
  brandingId: string,
  countryId: string | undefined
): string => {
  const branding = availableCountries
    .flatMap((item) => item.brandings)
    .find(
      (branding) =>
        branding && branding.brandingId === brandingId && branding.countryCode === countryId
    )
  return branding !== undefined ? `${branding.brandingName} (${countryId})` : brandingId
}

export enum UserIssue {
  USER_IS_BLOCKED = 'userIsBlocked',
  USER_HAS_NO_ROLES = 'userHasNoRoles',
  USER_HAS_NEVER_RECEIVED_PASSWORD = 'userHasNoInvite',
  USER_IS_LOCKED_OUT = 'isLockedOut',
  FAILED_LOGIN = 'failedLogin'
}

interface IssueDetector {
  issueKey: UserIssue
  detector: (user: User) => boolean
}

const issueDetectors: IssueDetector[] = [
  {
    issueKey: UserIssue.USER_IS_BLOCKED,
    detector: (user) => user.isBlocked
  },
  {
    issueKey: UserIssue.USER_HAS_NO_ROLES,
    detector: (user) => !Array.isArray(user.hasRoles) || user.hasRoles.length === 0
  },
  {
    issueKey: UserIssue.USER_HAS_NEVER_RECEIVED_PASSWORD,
    detector: (user) => !hasPassword(user)
  },
  {
    issueKey: UserIssue.USER_IS_LOCKED_OUT,
    detector: (user) => user.isLockedOut
  },
  {
    issueKey: UserIssue.FAILED_LOGIN,
    detector: (user) => user.failedLogin
  }
]

export const issuesForUser = (user: User): UserIssue[] =>
  issueDetectors.filter((detector) => detector.detector(user)).map((detector) => detector.issueKey)

export const getLiveProducts = (availableCountries: Country[] | null, user?: User): Product[] => {
  const liveProducts =
    availableCountries?.find((country) => country.countryCode === user?.country)?.liveProducts || []

  if (!liveProducts.includes(Product.HConnect)) {
    liveProducts.unshift(Product.HConnect)
  }

  return liveProducts
}

export const getMarketLabel = (market: Market): string => {
  if (!market.products || market.products.length === 0) {
    return market.marketName
  }

  return `(${market.products.join(', ')}) ${market.marketName || ''}`
}
export const compareMarketByLabel = (
  a: {value: string; label: string},
  b: {value: string; label: string}
) => {
  if (a.value === 'hproduce') {
    return -1
  }

  if (b.value === 'hproduce') {
    return 1
  }

  return a.label.localeCompare(b.label, i18next.language, {sensitivity: 'base'})
}
export const filterMarketsForPrimaryOrgUnitSelection = (market: Market) =>
  market.products !== null &&
  market.products.filter((product) => product.toLowerCase() === 'dispatchphonekey').length > 0

export const filterMarketsForMarketSelection = (market: Market) => {
  if (market.products === null || market.products.length === 0) {
    return true
  }

  const hasDeprecatedProduct = market.products?.includes('Deprecated')

  if (hasDeprecatedProduct) {
    return false
  }

  // TODO: remove once we keep dispatch phone keys in orders.
  const isDispatchPhoneKey =
    market.products.filter((product) => product.toLowerCase() === 'dispatchphonekey').length > 0

  if (isDispatchPhoneKey) {
    return false
  }

  return true
}
export enum UserInformationFormMode {
  CREATE = 'create',
  MANAGE = 'manage'
}
