import {CompanyMember} from '@hconnect/apiclient'
import {TableColumnType, useMobileBreakPoint, useTranslation} from '@hconnect/uikit'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import {Box, IconButton, Chip} from '@mui/material'

import {countryDataByCountryCode} from '../../../common/utils/utils'
import {useDetachUserAssignmentMutation} from '../../../hooks/useCompanyAssociatedUsers'
import {useStyles} from '../../../pages/UsersList/UsersListRows.styles'

export const AssociatedMemberColumns = (companyId: string) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const isMobile = useMobileBreakPoint()
  const countriesData = window.intlTelInputGlobals.getCountryData()
  const {mutateAsync: detachUserAssignment} = useDetachUserAssignmentMutation()

  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)

  const handleOnDetachUserAssignment = async (userId: string) => {
    const result = await detachUserAssignment([
      'companies',
      'members',
      {
        companyId: companyId,
        userId: userId
      }
    ])

    return result
  }

  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'name',
      mobileRowHeader: true,
      headerName: t('usersList.name'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      sortable: true,
      renderCell: (member: CompanyMember) => (
        <Box
          style={{
            maxWidth: isMobile ? '400px' : '250px',
            minWidth: '150px',
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'start'
          }}
        >
          <Box className={classes.textOverflow}>{member.name || '-'}</Box>
        </Box>
      )
    },
    lastActivityDate: {
      field: 'lastActivityDate',
      mobileRowHeader: true,
      headerName: t('usersList.lastActivityDate'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        const lastActivityDate = member?.lastActivityDate

        return (
          <Box>
            {lastActivityDate ? (
              <Box>
                <div className={classes.textOverflow}>
                  {new Date(lastActivityDate).toLocaleDateString(i18n.language)}
                </div>
              </Box>
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },
    eMail: {
      field: 'email',
      mobileRowHeader: true,
      headerName: t('usersList.email'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        return (
          <Box style={{wordBreak: isMobile ? 'break-all' : 'normal'}}>{member.email || '-'}</Box>
        )
      }
    },
    mobileNumber: {
      field: 'mobileNumber',
      mobileRowHeader: true,
      headerClassName: classes.columnHeader,
      headerName: t('usersList.mobileNumber'),
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        return <Box style={{display: 'flex'}}>{member.mobileNumber || '-'}</Box>
      }
    },
    country: {
      field: 'country',
      mobileRowHeader: true,
      headerName: t('usersList.country'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        return (
          <Box
            style={{
              display: 'flex'
            }}
          >
            {countryDataFilter(member.country)?.name || '-'}
          </Box>
        )
      }
    },
    createdOn: {
      field: 'createdOn',
      mobileRowHeader: true,
      headerClassName: classes.columnHeader,
      headerName: t('usersList.creationDate'),
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        const createText =
          member.createdOn !== undefined && member.createdOn !== null
            ? new Date(member.createdOn).toLocaleDateString(i18n.language)
            : '-'
        return (
          <Box>
            <div className={classes.textOverflow}>{createText}</div>
          </Box>
        )
      }
    },
    creationChannel: {
      field: 'creationChannel',
      mobileRowHeader: true,
      headerName: t('usersList.creationChannel'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        return (
          <Box style={{display: 'flex'}}>
            {member.creationChannel ? (
              <Chip
                className={classes.chip}
                size="small"
                label={t(`usersList.creationChip.${member.creationChannel}`)}
              />
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },
    actions: {
      field: 'actions',
      headerClassName: classes.columnHeader,
      headerName: isMobile ? '' : t('usersList.actions'),
      headerAlign: 'left',
      renderCell: (member: CompanyMember) => {
        return (
          <IconButton
            aria-label="unlink"
            color="primary"
            onClick={() => void handleOnDetachUserAssignment(member.id)}
          >
            <LinkOffIcon />
          </IconButton>
        )
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.actions,
    rowsPool.name,
    rowsPool.eMail,
    rowsPool.mobileNumber,
    rowsPool.country,
    rowsPool.createdOn,
    rowsPool.creationChannel,
    rowsPool.lastActivityDate
  ]

  return rowOrder(rowsPool)
}
