import {Box, Typography} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'

import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../modules/LoggedInUser.selectors'

import {useUserInformationFormData} from './hooks/useUserInformationFormData'
import {BlocklistNotificationsInput} from './inputs/BlocklistNotificationsInput'
import {TestUserInput} from './inputs/TestUserInput'
import {TfaInput} from './inputs/TfaInput'
import {UserBrandingInput} from './inputs/UserBrandingInput'
import {UserCompanyInput} from './inputs/UserCompanyInput'
import {UserCountryInput} from './inputs/UserCountryInput'
import {UserEmailInput} from './inputs/UserEmailInput'
import {UserLanguageInput} from './inputs/UserLanguageInput'
import {UserMarketInput} from './inputs/UserMarketInput'
import {UserMobileNumberInput} from './inputs/UserMobileNumberInput'
import {UserNameInput} from './inputs/UserNameInput'
import {UserOrgUnitInput} from './inputs/UserOrgUnitInput'
import {MessageHistory} from './MessageHistory/MessageHistory'
import {ProductActivity} from './ProductActivity/ProductActivity'
import {UserInformationInputSkeleton} from './Skeleton/UserInformationInputSkeleton'
import {useStyles} from './UserInformationForm.styles'
import {UserInformationFormProps} from './UserInformationForm.types'
import {UserInformationFormMode} from './utils/utils'

export const UserInformationForm = ({
  formMethods,
  formMode,
  readonlyMode,
  user,
  isLoading = false,
  tfaData,
  isTfaError,
  shouldShowTfaInput
}: UserInformationFormProps) => {
  const classes = useStyles()
  const {userId} = useParams<{userId: string}>()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const permissions = useSelector(selectLoggedInUserPermissions)
  const [showMfaInput, setShowMfaInput] = useState(shouldShowTfaInput)
  const values = formMethods.watch()
  const {
    brandings,
    countriesIds,
    createdByLink,
    createdByText,
    createdInText,
    createdOnText,
    createdViaText,
    marketName,
    marketSelectValues,
    primaryOrgUnitsMarketsSelectValues,
    primaryOrganisationalUnitLabel,
    selectedCountry
  } = useUserInformationFormData(values, user)

  useEffect(() => {
    formMethods.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonlyMode])

  const canEditUserEmailPhone =
    formMode === UserInformationFormMode.CREATE ||
    loggedInUserProfile?.id === userId ||
    permissions.some((permission) => permission.permissionType === 'CHANGE_USER_LOGIN')

  const canSeeMessageHistory = permissions.some(
    (permission) => permission.permissionType === 'MANAGE_MESSAGES'
  )

  return (
    <Box className={classes.userInfoSection}>
      <div className={classes.form}>
        <UserNameInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          isLoading={isLoading}
        />
        <UserEmailInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          canEditUserEmailPhone={canEditUserEmailPhone}
          isLoading={isLoading}
          setShowMfaInput={setShowMfaInput}
        />
        <UserMobileNumberInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          canEditUserEmailPhone={canEditUserEmailPhone}
          userMobileNumber={user?.mobileNumber}
          isLoading={isLoading}
        />
        <UserCompanyInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          isLoading={isLoading}
        />
        <UserCountryInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          countriesIds={countriesIds}
          isLoading={isLoading}
          selectedCountry={selectedCountry}
        />
        <UserBrandingInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          brandings={brandings}
          user={user}
          isLoading={isLoading}
        />
        <UserOrgUnitInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          primaryOrganisationalUnitLabel={primaryOrganisationalUnitLabel}
          primaryOrgUnitsMarketsSelectValues={primaryOrgUnitsMarketsSelectValues}
          isLoading={isLoading}
        />
        <UserMarketInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          marketName={marketName}
          marketSelectValues={marketSelectValues}
          isLoading={isLoading}
        />
        <UserLanguageInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          selectedCountry={selectedCountry}
          isLoading={isLoading}
        />
        <TestUserInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          isTester={user?.isTester}
          isLoading={isLoading}
        />
        {showMfaInput && !isTfaError && (
          <TfaInput formMethods={formMethods} isLoading={isLoading} tfaData={tfaData} />
        )}
        <BlocklistNotificationsInput
          readonlyMode={readonlyMode}
          formMethods={formMethods}
          isLoading={isLoading}
        />
        {isLoading ? (
          <UserInformationInputSkeleton single={true} firstRowWidth={600} />
        ) : (
          readonlyMode && (
            <Typography variant="caption" color="secondary">
              {createdOnText} {createdByText} {createdByLink} {createdViaText} {createdInText}
            </Typography>
          )
        )}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: '12px', flex: 1}}>
        <div>
          {formMode === UserInformationFormMode.MANAGE && (
            <ProductActivity lastActivityDates={user?.lastActivityDates} isLoading={isLoading} />
          )}
        </div>
        {formMode === UserInformationFormMode.MANAGE && canSeeMessageHistory && (
          <div data-test-id={`user-messages-${user?.user_id ?? ''}`}>
            <MessageHistory userId={user?.user_id || ''} />
          </div>
        )}
      </div>
    </Box>
  )
}
