import {CompanyFormTypes} from './components/Company/inputs/types'
import {UserInformationFormTypes} from './components/UserInformation/inputs/types'
import {CompanyErrorResponse, CompanyValidationError} from './validations/types'

export type BackendFieldError = {
  fieldName: string
  validationErrorType: string
}

export const BackendValidationError = 'https://api.hce.heidelbergcement.com/errors/validationError'
export const BackendValidationErrorUserAlreadyExists =
  'https://api.hce.heidelbergcement.com/errors/userAlreadyExists'
export const BackendValidationErrorCompanyAlreadyExists = 'already exists'
export const BackendValidationErrorCompanyNameNotEmpty = "'Name' must not be empty."

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleValidationError = (error: any, formMethods: UserInformationFormTypes) => {
  if (
    error?.response?.data?.type === BackendValidationError ||
    error?.response?.data?.type === BackendValidationErrorUserAlreadyExists
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    handleBackendFieldValidationError(error?.response?.data?.extendedData?.fieldErrors, formMethods)
  }
  return error.response?.data.detail ?? error.response?.data.title ?? error.message
}
export const InternalErrors = {
  PHONE_OR_EMAIL_REQUIRED: 'PHONE_OR_EMAIL_REQUIRED',
  INVALID_MOBILE_NUMBER: 'INVALID_MOBILE_NUMBER',
  USER_MOBILE_NUMBER_ALREADY_EXISTS: 'USER_MOBILE_NUMBER_ALREADY_EXISTS',
  USER_EMAIL_ALREADY_EXISTS: 'USER_EMAIL_ALREADY_EXISTS',
  COMPANY_NAME_ALREADY_EXISTS: 'COMPANY_NAME_ALREADY_EXISTS',
  COMPANY_NAME_EMPTY: 'COMPANY_NAME_EMPTY'
}

export const handleBackendFieldValidationError = (
  fieldErrors: BackendFieldError[],
  formMethods: UserInformationFormTypes
) => {
  if (fieldErrors?.length > 0) {
    fieldErrors?.forEach((fieldError: BackendFieldError) => {
      switch (fieldError.fieldName.toLowerCase()) {
        case 'email':
          handleEmailFieldErrors(fieldError, formMethods)
          break
        case 'mobilenumber':
          handleMobileNumberFieldErrors(fieldError, formMethods)
          break
      }
    })
  }
}

export const handleEmailFieldErrors = (
  fieldError: BackendFieldError,
  formMethods: UserInformationFormTypes
) => {
  let msgKey = ''
  if (fieldError.validationErrorType.toLowerCase() === 'missing') {
    msgKey = InternalErrors.PHONE_OR_EMAIL_REQUIRED
  } else if (fieldError.validationErrorType.toLowerCase() === 'exists') {
    msgKey = InternalErrors.USER_EMAIL_ALREADY_EXISTS
  }
  if (msgKey) {
    formMethods.setError('eMail', {
      message: msgKey
    })
  }
}

export const handleMobileNumberFieldErrors = (
  fieldError: BackendFieldError,
  formMethods: UserInformationFormTypes
) => {
  let msgKey = ''
  if (fieldError.validationErrorType.toLowerCase() === 'missing') {
    msgKey = InternalErrors.PHONE_OR_EMAIL_REQUIRED
  } else if (fieldError.validationErrorType.toLowerCase() === 'exists') {
    msgKey = InternalErrors.USER_MOBILE_NUMBER_ALREADY_EXISTS
  } else {
    msgKey = InternalErrors.INVALID_MOBILE_NUMBER
  }
  if (msgKey) {
    formMethods.setError('mobileNumber', {
      message: msgKey
    })
  }
}

export const handleCompanyValidationError = (
  errorResponse: CompanyErrorResponse | undefined,
  formMethods: CompanyFormTypes
) => {
  const errors = errorResponse?.errors ?? []

  errors.forEach((error: CompanyValidationError) => {
    switch (error.propertyName.toLowerCase()) {
      case 'name':
        if (error.errorMessage === BackendValidationErrorCompanyNameNotEmpty) {
          formMethods.setError('name', {
            message: InternalErrors.COMPANY_NAME_EMPTY
          })
        } else if (error.errorMessage.includes(BackendValidationErrorCompanyAlreadyExists)) {
          formMethods.setError('name', {
            message: InternalErrors.COMPANY_NAME_ALREADY_EXISTS
          })
        }
        break
    }
  })
  return errorResponse?.errors[0]?.errorMessage
}
