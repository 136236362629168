import {Add, DeleteOutline, Loop} from '@mui/icons-material'
import {Box, Button, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DeleteConfirmationType} from '../TfaDialog'
import {useStyles} from '../TfaDialog.styles'

type EditTfaDialogContentProps = {
  setShowDeleteConfirmation: (confirmation?: DeleteConfirmationType) => void
  tfaType: 'authenticatorApp' | 'phoneNumber' | 'none'
  setEditMode: (editMode: boolean) => void
}
export const EditTfaDialogContent = ({
  setShowDeleteConfirmation,
  tfaType,
  setEditMode
}: EditTfaDialogContentProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()

  return (
    <Box style={{display: 'flex', flexDirection: 'column'}}>
      {tfaType !== 'none' && (
        <>
          <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
            {t('userInformationForm.tfa.edit.description')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              marginTop: '24px',
              marginLeft: '16px'
            }}
          >
            <Typography variant="h4" style={{color: '#54708C', marginRight: '20px'}}>
              {tfaType === 'authenticatorApp'
                ? t('userInformationForm.tfa.edit.authApp')
                : t('userInformationForm.tfa.edit.phoneNumber')}
            </Typography>
            <IconButton
              style={{boxShadow: 'none'}}
              onClick={() => setShowDeleteConfirmation({tfaType, reason: 'delete'})}
              data-test-id={'delete-tfa-type'}
            >
              <DeleteOutline style={{color: '#4E565E', width: '24px', height: '24px'}} />
            </IconButton>
          </Box>
          <Button
            variant="text"
            color="primary"
            onClick={() => setShowDeleteConfirmation({tfaType, reason: 'replace'})}
            className={classes.addSecurityMethod}
            data-test-id="button-add-new-security-method"
          >
            <Loop className={classes.icon} style={{color: '#016AD4'}} />
            {t('userInformationForm.tfa.replaceSecurity')}
          </Button>
        </>
      )}
      {tfaType === 'none' && (
        <>
          <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
            {t('userInformationForm.tfa.edit.noTfaType')}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => setEditMode(false)}
            className={classes.addSecurityMethod}
            data-test-id="button-add-new-security-method"
          >
            <Add className={classes.icon} style={{color: '#016AD4'}} />
            {t('userInformationForm.tfa.addSecurity')}
          </Button>
        </>
      )}
    </Box>
  )
}
