import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useQueryClient, useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {CompanyErrorResponse} from '../../src/validations/types'
import {api} from '../App.store'

type UseAssignUserToCompanyQueryKey = ['companies', 'members', {companyId: string; userId: string}]
type UseDetachUserAssignmentFromCompanyQueryKey = [
  'companies',
  'members',
  {companyId: string; userId: string}
]

const assignmentUserToCompany = () => async (queryKey: UseAssignUserToCompanyQueryKey) => {
  try {
    const [, , {companyId, userId}] = queryKey
    const response = await api.post(`/companies/${companyId}/members/${userId}`)
    return response.data
  } catch (err) {
    const error = err as AxiosError
    const errorDetails = error.response?.data as CompanyErrorResponse
    throw new Error(errorDetails?.errors[0]?.errorMessage)
  }
}

const detachAssignmentUserFromCompany =
  () => async (queryKey: UseDetachUserAssignmentFromCompanyQueryKey) => {
    const [, , {companyId, userId}] = queryKey
    try {
      const response = await api.delete(`/companies/${companyId}/members/${userId}`)
      return response.data
    } catch (error) {
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: (error as AxiosError).response?.status,
        component: 'useDetachUserAssignmentMutation',
        endpoint: '/companies/members'
      })
    }
  }

export const useAssignUserMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(assignmentUserToCompany(), {
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['companies', 'manage'])
    }
  })
}

export const useDetachUserAssignmentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(detachAssignmentUserFromCompany(), {
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['companies', 'manage'])
    }
  })
}
