import {PhoneNumberTextField} from '@hconnect/uikit'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material'
import classNames from 'classnames'
import intlTelInput from 'intl-tel-input'
import QRCode from 'qrcode.react'
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {StepIconComponent} from '../StepIconComponent/StepIconComponent'
import {PhoneDataType, TfaType} from '../TfaDialog'
import {useStyles} from '../TfaDialog.styles'

type AddTfaDialogContentProps = {
  activeTab: TfaType
  setActiveTab: (tab: TfaType) => void
  activeStep: number
  steps: string[]
  otpData: {sharedKey: string; qrCodeUri: string}
  isVerifyAppCodeError: boolean
  isVerifyMobileCodeError: boolean
  setCode: (code: string) => void
  isAddPhoneNumberError: boolean
  canSetupAppTfa: boolean
  phoneData: PhoneDataType | undefined
  setPhoneData: (data: PhoneDataType | undefined) => void
  errorMessage?: string
  recoveryCodesData?: string[]
}
export const AddTfaDialogContent = ({
  activeTab,
  setActiveTab,
  activeStep,
  steps,
  otpData,
  isVerifyMobileCodeError,
  isVerifyAppCodeError,
  errorMessage,
  setCode,
  isAddPhoneNumberError,
  recoveryCodesData,
  canSetupAppTfa,
  phoneData,
  setPhoneData
}: AddTfaDialogContentProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()

  const phoneNumberRef = useRef(null)
  const handlePhoneNumberChange = (
    phoneNumberInput: string,
    countryData?: intlTelInput.CountryData
  ) => {
    const numbersAndPlusOnly = /^[0-9+]*$/
    if (numbersAndPlusOnly.test(phoneNumberInput)) {
      setPhoneData({phoneNumber: phoneNumberInput, countryData})
    }
  }

  const BpIcon = () => <span className={classes.radioIcon} />

  const BpCheckedIcon = () => (
    <span className={classNames(classes.icon, classes.radioCheckedIcon)} />
  )

  const renderTfaRadioButtonGroup = () => {
    return (
      <RadioGroup
        defaultValue={activeTab}
        onChange={(_, value) => setActiveTab(parseInt(value))}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '36px',
          paddingLeft: '20px',
          gap: '40px'
        }}
      >
        <FormControlLabel
          value={TfaType.APP}
          control={
            <Radio
              classes={{root: classes.radio}}
              data-test-id="tfa-authenticator-radio-button"
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
            />
          }
          label={
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginTop: '6px',
                marginLeft: '12px'
              }}
            >
              <Box style={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
                <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
                  {t('userInformationForm.tfa.add.tabAuthenticator')}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: !canSetupAppTfa ? undefined : '#00AB50'
                  }}
                >
                  {!canSetupAppTfa
                    ? '(' + t('userInformationForm.tfa.add.tabAuthenticatorDisabled') + ')'
                    : '(' + t('userInformationForm.tfa.add.tabAuthenticatoRecommended') + ')'}
                </Typography>
              </Box>
              <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
                {t('userInformationForm.tfa.add.app.description')}
              </Typography>
            </Box>
          }
          style={{alignItems: 'start'}}
          disabled={!canSetupAppTfa}
        />
        <FormControlLabel
          value={TfaType.MOBILE}
          control={
            <Radio
              classes={{root: classes.radio}}
              data-test-id="tfa-mobile-radio-button"
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
            />
          }
          label={
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginTop: '6px',
                marginLeft: '12px'
              }}
            >
              <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
                {t('userInformationForm.tfa.add.mobile.label')}
              </Typography>
              <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
                {t('userInformationForm.tfa.add.mobile.description')}
              </Typography>
            </Box>
          }
          style={{alignItems: 'start'}}
        />
      </RadioGroup>
    )
  }

  const firstStep = (
    <>
      <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
        {t('userInformationForm.tfa.add.title')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column'}}>{renderTfaRadioButtonGroup()}</Box>
    </>
  )

  const secondStep = (
    <>
      {activeTab === TfaType.APP && (
        <>
          <Typography
            variant="caption"
            style={{marginTop: '24px', marginBottom: '30px', fontSize: '16px', lineHeight: '22px'}}
          >
            {t('userInformationForm.tfa.add.app.instruction')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            <QRCode id="qrcode" data-test-id="qrcode" size={150} value={otpData?.qrCodeUri} />
            <Typography
              variant="caption"
              style={{fontSize: '16px', lineHeight: '22px', textAlign: 'center'}}
              data-test-id="shared-key"
            >
              {otpData?.sharedKey}
            </Typography>
          </Box>
        </>
      )}
      {activeTab === TfaType.MOBILE && (
        <>
          <Typography
            variant="caption"
            style={{marginTop: '24px', fontSize: '16px', lineHeight: '22px'}}
          >
            {t('userInformationForm.tfa.add.mobile.instruction')}
          </Typography>

          <PhoneNumberTextField
            inputRef={phoneNumberRef}
            variant="filled"
            label={t('userInformationForm.tfa.add.mobile.label')}
            data-test-id="mobile-number-input"
            className={classes.mobileNumber}
            value={phoneData?.phoneNumber}
            onPhoneChange={(phoneNumber, _, itiGetCountry) => {
              handlePhoneNumberChange(phoneNumber, itiGetCountry)
            }}
            error={isAddPhoneNumberError}
            helperText={isAddPhoneNumberError && errorMessage}
            readonlyMode={false}
            focused={true}
            InputProps={{disableUnderline: true}}
            InputLabelProps={{focused: false}}
          />
        </>
      )}
    </>
  )

  const thirdStep = (
    <>
      <Typography
        variant="caption"
        style={{marginTop: '24px', fontSize: '16px', lineHeight: '22px'}}
      >
        {t(
          `userInformationForm.tfa.add.authCode.${
            activeTab === TfaType.APP ? 'descriptionApp' : 'descriptionMobile'
          }`
        )}
      </Typography>
      <TextField
        variant="filled"
        data-test-id="activation-code-input"
        label={t('userInformationForm.tfa.add.authCode.label')}
        className={classes.mobileNumber}
        focused={true}
        onChange={(event) => setCode(event.target.value)}
        error={isVerifyAppCodeError || isVerifyMobileCodeError}
        helperText={(isVerifyAppCodeError || isVerifyMobileCodeError) && errorMessage}
      />
    </>
  )

  const fourthStep = (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
      <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
        {t('userInformationForm.tfa.add.success')}
      </Typography>
      {recoveryCodesData && (
        <>
          <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
            {t('userInformationForm.tfa.add.recoveryCodes')}
          </Typography>
          <Box
            style={{display: 'flex', flexDirection: 'column'}}
            data-test-id="recovery-codes-container"
          >
            {recoveryCodesData.map((code) => (
              <Typography
                key={code}
                variant="caption"
                style={{fontSize: '16px', lineHeight: '22px'}}
              >
                {code}
              </Typography>
            ))}
          </Box>
        </>
      )}
    </Box>
  )
  return (
    <Box style={{display: 'flex', flexDirection: 'column'}}>
      <Stepper activeStep={activeStep} className={classes.stepConnector}>
        {steps.map((label, index) => (
          <Step key={label} style={{padding: 0}}>
            <StepLabel
              classes={{iconContainer: classes.stepIconContainer}}
              StepIconComponent={() => StepIconComponent(label, index, activeStep, steps)}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && firstStep}
      {activeStep === 1 && secondStep}
      {activeStep === 2 && thirdStep}
      {activeStep === 3 && fourthStep}
    </Box>
  )
}
