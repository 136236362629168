import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AutoSelectDropdown, Option} from '../../../common/AutoSelectDropdown'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserOrgUnitInputProps = UserInformationProps & {
  primaryOrgUnitsMarketsSelectValues: Option[]
  primaryOrganisationalUnitLabel?: string | null
}

export const UserOrgUnitInput = ({
  readonlyMode,
  formMethods,
  primaryOrganisationalUnitLabel,
  primaryOrgUnitsMarketsSelectValues,
  isLoading
}: UserOrgUnitInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()
  const values = formMethods.watch()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  const defaultLocaleValue = formMethods.formState.defaultValues?.defaultLocale

  return (
    <>
      {readonlyMode ? (
        <TextField
          label={t('userInformationForm.primaryOrganisationalUnit')}
          value={primaryOrganisationalUnitLabel}
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      ) : (
        primaryOrgUnitsMarketsSelectValues.length > 0 && (
          <Controller
            name="primaryOrganisationalUnit"
            control={formMethods.control}
            render={({field}) => (
              <AutoSelectDropdown
                autoSelectSingleOption={false}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                inputRef={field.ref}
                data-test-id={`userInformationForm-${field.name}`}
                fullWidth
                className={classes.textField}
                select={!readonlyMode}
                error={touched.primaryOrganisationalUnit && !!errors.primaryOrganisationalUnit}
                disabled={!values.country || primaryOrgUnitsMarketsSelectValues.length === 0}
                label={t('userInformationForm.primaryOrganisationalUnit')}
                emptyItem
                emptyItemLabel={t('userInformationForm.none')}
                options={primaryOrgUnitsMarketsSelectValues}
                setFieldValue={(_, value) =>
                  formMethods.setValue(field.name, value, {shouldValidate: true})
                }
                style={{
                  width: defaultLocaleValue === 'fr-FR' ? 600 : 450
                }}
                SelectProps={{
                  MenuProps: {
                    style: {
                      width: 500
                    }
                  }
                }}
                helperText={!values.country && t('userInformationForm.brandingHelperText')}
                margin="normal"
                InputProps={{readOnly: readonlyMode, disableUnderline: readonlyMode}}
              />
            )}
          />
        )
      )}
    </>
  )
}
