import {Country} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'
import {RoleAssignment} from '../../modules/ManageUsers.selectors'

const countriesConfigurationFetcher: QueryFunction<Country[]> = async ({queryKey}) => {
  const [base, endpoint] = queryKey
  try {
    const response = await api.get<Country[]>(`/${base}/${endpoint}`)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error('No cross-countries found!')
    }
    throw new Error('Error fetching user cross-country information')
  }
}

export const useUserCrossCountriesConfiguration = (
  loggedInUserRoles: RoleAssignment[] | null | undefined
) => {
  const shouldFetch = loggedInUserRoles?.some((userRole) => {
    return (
      userRole.roleType === 'SUPER_ADMIN' &&
      userRole.dataScope &&
      Object.keys(userRole.dataScope).length === 1 &&
      userRole.dataScope.countryId
    )
  })

  const query = useQuery<Country[], AxiosError>(
    ['configuration', 'roles/crosscountriesconfig'],
    countriesConfigurationFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!loggedInUserRoles && shouldFetch
    }
  )

  const isLoading = !!loggedInUserRoles && shouldFetch && query.isLoading

  return {
    ...query,
    isLoading
  }
}
