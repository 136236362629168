import {TextField} from '@mui/material'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {InputSkeleton} from '../skeletons/InputSkeleton'

import {useStyles} from './FormInputs.styles'

export interface FormTextInputProps {
  readonlyMode: boolean
  formMethods: any
  isLoading: boolean
  name: string
  labelTranslationKey: string
  autoComplete?: string
  testIdPrefix: string
  rules?: any
}

export const FormTextInput = ({
  readonlyMode,
  formMethods,
  isLoading,
  name,
  labelTranslationKey,
  autoComplete = 'off',
  testIdPrefix,
  rules
}: FormTextInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const {classes} = useStyles()

  if (isLoading) {
    return <InputSkeleton />
  }

  const hasError = !!errors[name]?.message
  const localKey = `errorMessages.${errors[name]?.message}`
  const helperText = hasError ? t(localKey) : ''

  return (
    <Controller
      name={name}
      control={formMethods.control}
      rules={rules}
      render={({field}) => (
        <TextField
          {...field}
          value={readonlyMode && !field.value ? '-' : field.value}
          data-test-id={`${testIdPrefix}-${field.name}`}
          className={classes.textField}
          fullWidth={true}
          id={name}
          label={t(labelTranslationKey)}
          autoComplete={autoComplete}
          margin="normal"
          error={!!errors[name]}
          helperText={helperText}
          style={{maxWidth: 600}}
          InputProps={{
            readOnly: readonlyMode,
            disableUnderline: readonlyMode
          }}
          variant={readonlyMode ? 'standard' : 'filled'}
        />
      )}
    />
  )
}
