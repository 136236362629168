import {TableColumnType, useTranslation} from '@hconnect/uikit'
import {Box, Chip, Link, Tooltip} from '@mui/material'
import classNames from 'classnames'
import {upperFirst} from 'lodash'
import moment from 'moment'
import {useHistory} from 'react-router-dom'

import {UserMessageHistory} from '../hooks/useMessageHistory'

import {useStyles} from './MessageHistory.styles'

export const MessageHistoryColumns = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const {classes} = useStyles()

  const messageLink = (message: string, id: number): JSX.Element => {
    const displayMsg = message.length > 25 ? `${message.substring(0, 25)} ...` : message
    const shouldShowTooltip = message.length > 25
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      history.push(`/messages/details/${id}`, {redirect: 'users'})
    }

    return (
      <Tooltip title={message} arrow placement="bottom" disableHoverListener={!shouldShowTooltip}>
        <Link
          data-test-id={`messages-${message}`}
          style={{whiteSpace: 'pre-wrap'}}
          onClick={handleClick}
        >
          {displayMsg}
        </Link>
      </Tooltip>
    )
  }

  const rowsPool: {[key: string]: TableColumnType} = {
    messageTitle: {
      field: 'subject',
      headerName: t('userInformationForm.subject'),
      headerAlign: 'left',
      renderCell: (messageHistory: UserMessageHistory) => (
        <Box style={{maxWidth: '200px'}}>
          {messageLink(
            messageHistory.subject?.trim() ? messageHistory.subject : messageHistory.body,
            messageHistory.id
          )}
        </Box>
      )
    },
    messageType: {
      field: 'type',
      headerName: t('messages.channel'),
      headerAlign: 'left',
      renderCell: (messageHistory: UserMessageHistory) => (
        <Box data-test-id={`messages-${messageHistory.type}`}>
          <Chip
            classes={{
              root: classNames({
                [classes.tags]: true,
                [classes.tagMod1]: true
              })
            }}
            key={messageHistory.type}
            label={upperFirst(messageHistory.type)}
            size="small"
          />
        </Box>
      )
    },
    sentDate: {
      field: 'sentDate',
      headerName: t('userInformationForm.sentDate'),
      headerAlign: 'left',
      renderCell: (messageHistory: UserMessageHistory) => (
        <Box data-test-id={`messages-${messageHistory.sentDate}`}>
          {moment(messageHistory.sentDate).format('DD/MM/YYYY')}
        </Box>
      )
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.messageTitle,
    rowsPool.messageType,
    rowsPool.sentDate
  ]
  return rowOrder(rowsPool)
}
