import {Plant, getPlantById} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

type PlantParam = {
  plantId: string
  countryId: string
}

const fetcher: QueryFunction<Plant, [string, PlantParam]> = ({
  queryKey
}: {
  queryKey: [string, PlantParam]
}): Promise<Plant> => {
  const [, {plantId, countryId}] = queryKey

  return getPlantById(api)(plantId, countryId)
}

export const usePlant = ({plantId, countryId}: PlantParam) => {
  return useQuery(['plants', {plantId, countryId}], fetcher, {retry: false})
}
