import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  rolesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `0 -${theme.spacing(1)}px`
  }
}))

export const RolesContainer = ({children}: {children?: React.ReactNode}) => {
  const classes = useStyles()
  return <div className={classes.rolesContainer}>{children}</div>
}
