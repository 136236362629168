import {Payer, getPayerById, getPayerWithoutNumber} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

type CustomerParam = {
  payerId: string
}

type PayerWithoutNumberParam = {
  countryId: string
  orgUnitId: string
  businessLine: string
}

const fetcher: QueryFunction<Payer, [string, CustomerParam]> = ({
  queryKey
}: {
  queryKey: [string, CustomerParam]
}): Promise<Payer> => {
  const [, {payerId}] = queryKey

  return getPayerById(api)(payerId)
}

const fetcherWithoutNumber: QueryFunction<Payer[], [string, PayerWithoutNumberParam]> = ({
  queryKey
}: {
  queryKey: [string, PayerWithoutNumberParam]
}): Promise<Payer[]> => {
  const [, {countryId, orgUnitId, businessLine}] = queryKey

  return getPayerWithoutNumber(api)({countryId, orgUnitId, businessLine})
}

export const usePayer = ({payerId}: CustomerParam) => {
  return useQuery(['payers', {payerId}], fetcher, {retry: false})
}

export const usePayerWithoutNumber = (
  countryId: string,
  orgUnitId: string,
  businessLine: string,
  enabled?: boolean
) => {
  return useQuery(['payers', {countryId, orgUnitId, businessLine}], fetcherWithoutNumber, {
    retry: false,
    enabled
  })
}
