import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  root: {
    top: '64px !important'
  },
  frameStyle: {
    width: '480px',
    height: '100vh',
    margin: '0 auto',
    border: '1px solid #2196F3',
    padding: '10px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  headerLogoContainer: {
    marginBottom: theme.spacing(1)
  }
}))
