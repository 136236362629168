import {TableColumnType, useTranslation} from '@hconnect/uikit'
import {Box, Chip, Link, Typography} from '@mui/material'
import classNames from 'classnames'
import {upperFirst} from 'lodash'
import moment from 'moment'
import React from 'react'

import {Audit} from '../../../pages/ManageUser/hooks/useAuditInformation'

import {useStyles} from './AuditInformationColumn.styles'

export const AuditInformationColumns = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const renderObjectProperties = (obj: unknown): string => {
    if (typeof obj !== 'object' || obj === null) {
      return typeof obj === 'string' ? obj?.toString() : ''
    }
    const excludedKeys = [
      'Id',
      'UserId',
      'AutoGenerated',
      'LastModifiedAt',
      'LastModifiedBy',
      'ModifiedOn',
      'ModifiedBy',
      'ConcurrencyStamp',
      'ProductInvitations',
      'RoleRequestId'
    ]

    return Object.entries(obj)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([key, value]) => {
        const renderedProperties = renderObjectProperties(value)
        if (renderedProperties) {
          const dateRegex = /\d{4}-\d{2}-\d{2}/
          const formattedText = dateRegex.test(renderedProperties)
            ? moment(renderedProperties).format('DD/MM/YYYY hh:mm')
            : renderedProperties

          return key === 'DataScope' ? `${formattedText}` : `${key}: ${formattedText}`
        }
        return null
      })
      .filter((res) => res !== null)
      .join('\n')
  }

  const RenderedText = (FormattedText: string): JSX.Element => {
    const lines = FormattedText.split('\n')

    return (
      <Box>
        {lines.map((line, index) => (
          <Box key={index}>
            <Typography variant="caption">{line}</Typography>
          </Box>
        ))}
      </Box>
    )
  }

  const ModifiedByLink = (modifiedBy: string): JSX.Element | string => {
    const systemText = modifiedBy.includes('UserId')
    if (systemText || isNullOrEmpty(modifiedBy)) return t('audit.systemEvent')

    return (
      <Link href={`/manage/${modifiedBy}`} target="_blank" rel="noopener noreferrer">
        {modifiedBy}
      </Link>
    )
  }
  const isNullOrEmpty = (str: string | null | undefined): boolean => !str || str.trim().length === 0

  const rowsPool: {[key: string]: TableColumnType} = {
    eventDateTime: {
      field: 'eventDateTime',
      headerName: t('audit.dateTime'),
      headerAlign: 'left',
      renderCell: (audit: Audit) => (
        <Box style={{whiteSpace: 'nowrap'}}>
          <Typography variant="caption">
            {moment(audit.eventDateTime).format('DD/MM/YYYY hh:mm')}
          </Typography>
        </Box>
      )
    },
    action: {
      field: 'action',
      headerName: t('audit.action'),
      headerAlign: 'left',
      renderCell: (audit: Audit) => (
        <Typography variant="caption">
          <Chip
            classes={{
              root: classNames({
                [classes.tags]: true,
                [classes.tagMod1]: true
              })
            }}
            key={audit.action}
            label={upperFirst(t(audit.action))}
            size="small"
          />
        </Typography>
      )
    },
    modifiedBy: {
      field: 'modifiedBy',
      headerName: t('audit.modifiedBy'),
      headerAlign: 'left',
      renderCell: (audit: Audit) => (
        <Box>
          <Typography variant="caption" className={classes.textOverflow}>
            {ModifiedByLink(audit.modifiedBy)}
          </Typography>
        </Box>
      )
    },
    oldValue: {
      field: 'oldValue',
      headerName: t('audit.oldValue'),
      headerAlign: 'left',
      renderCell: (audit: Audit) => <>{RenderedText(renderObjectProperties(audit.oldValue))}</>
    },
    newValue: {
      field: 'newValue',
      headerName: t('audit.newValue'),
      headerAlign: 'left',
      renderCell: (audit: Audit) => <>{RenderedText(renderObjectProperties(audit.newValue))}</>
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.eventDateTime,
    rowsPool.action,
    rowsPool.modifiedBy,
    rowsPool.oldValue,
    rowsPool.newValue
  ]
  return rowOrder(rowsPool)
}
