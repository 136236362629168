import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DeleteConfirmationType} from '../TfaDialog'

export type DeleteTfaDialogContentProps = {
  showDeleteConfirmation?: DeleteConfirmationType
}
export const DeleteTfaDialogContent = ({showDeleteConfirmation}: DeleteTfaDialogContentProps) => {
  const {t} = useTranslation()

  return (
    <Box style={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="caption" style={{fontSize: '16px', lineHeight: '22px'}}>
        {showDeleteConfirmation?.reason === 'delete'
          ? t('userInformationForm.tfa.delete.removeDescription')
          : t('userInformationForm.tfa.delete.replaceDescription')}
      </Typography>
    </Box>
  )
}
