import {User, getUsers} from '@hconnect/apiclient'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@mui/icons-material/Check'
import {Button} from '@mui/material'
import {debounce} from 'lodash'
import {useEffect} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {api} from '../../../App.store'
import {FormTextInput} from '../../../common/inputs/FormTextInput'
import {validateEmailFormat} from '../../../common/validateEmailFormat'
import {useAssignUserMutation} from '../../../hooks/useCompanyAssociatedUsers'

import {UserAssignmentFormData} from './CompanyMemberAssociation.types'
import {useStyles} from './ManageCompanyMembers.styles'

const incorrectRoleBackendErrorMessage = 'does not possess the correct role'

export const UserAssignmentForm = ({
  handleOnCancel,
  formMethods
}: {
  handleOnCancel: () => void
  formMethods: UseFormReturn<UserAssignmentFormData, object>
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {
    mutateAsync: mutateUserAssignment,
    isSuccess: isAssignmentSucceed,
    isLoading: userAssignmentProcessing,
    isError: isErrorDuringUserAssignment,
    error: UserAssignmentError
  } = useAssignUserMutation()

  useEffect(() => {
    if (isAssignmentSucceed) {
      handleOnCancel()
      formMethods.reset()
    }
    if (isErrorDuringUserAssignment) {
      const assignmentError = UserAssignmentError as Error
      if (assignmentError.message.includes(incorrectRoleBackendErrorMessage)) {
        formMethods.setError('email', {type: 'manual', message: 'USER_INCORRECT_ROLE'})
      } else {
        formMethods.setError('email', {type: 'manual', message: 'COULD_NOT_ASSIGN_USER'})
      }
    }
  }, [
    isAssignmentSucceed,
    handleOnCancel,
    formMethods,
    isErrorDuringUserAssignment,
    UserAssignmentError
  ])

  const handleOnSubmitForm = async () => {
    const formValue = formMethods.getValues()
    try {
      await mutateUserAssignment([
        'companies',
        'members',
        {
          companyId: formValue.companyId,
          userId: formValue.userId
        }
      ])
    } catch (error) {
      // Do nothing, the error is handled in the `useEffect` above
    }
  }

  return (
    <form id="assign-member-form" onSubmit={formMethods.handleSubmit(handleOnSubmitForm)}>
      <div className={classes.formContent}>
        <FormTextInput
          readonlyMode={false}
          formMethods={formMethods}
          isLoading={userAssignmentProcessing}
          name="email"
          labelTranslationKey="company.member.assignDialog.emailLabel"
          testIdPrefix="company-member-input"
          rules={{
            required: {value: true, message: 'EMAIL_REQUIRED'}, // Use the translation key directly of errorMessages, which is translated in `FormTextInput`
            validate: {
              emailFormat: (value: string) => {
                return validateEmailFormat(value)
              },
              emailExist: debounce(
                async (value) => {
                  const result = await getUsers(api)({eMail: value})
                  const user = result?.data?.find((user: User) => user.eMail === value)
                  if (!user) {
                    // Use the translation key directly of errorMessages, which is translated in `FormTextInput`
                    return 'USER_NOT_FOUND'
                  }
                  formMethods.setValue('userId', user.id)
                  return true
                },
                500,
                {leading: true}
              )
            }
          }}
        />
      </div>
      <div className={classes.formFooter} data-test-id="assign-member-dialog-actions">
        <div className={classes.formFooterButtonGroup}>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => {
              formMethods.reset()
              handleOnCancel()
            }}
            data-test-id="assign-member-dialog-btn-cancel"
          >
            {t('company.member.assignDialog.cancelButton')}
          </Button>

          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            type="submit"
            data-test-id="assign-member-dialog-btn-submit"
          >
            {t('company.member.assignDialog.assignButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}
