import {Company} from '@hconnect/apiclient'
import {useMobileBreakPoint, useTranslation} from '@hconnect/uikit'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {Box, Button, Grid, LinearProgress, Paper, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'

import {FormTextInput} from '../../common/inputs/FormTextInput'
import {handleCompanyValidationError} from '../../errorHandling'
import {useModifyCompanyMutation} from '../../hooks/useCompany'
import {CompanyErrorResponse} from '../../validations/types'

import {CompanyFormData} from './CompanyForm.types'
import {useCompanyFormData} from './hooks/useCompanyFormData'
import {CompanyCountryInput} from './inputs/CompanyCountryInput'
import {useStyles} from './ManageCompanyView.styles'

export const CompanyDetailView = ({
  formMethods
}: {
  formMethods: UseFormReturn<CompanyFormData, object>
}) => {
  const isMobile = useMobileBreakPoint()
  const [readonlyMode, setReadonlyMode] = useState(true)
  const {
    mutate: updateCompanyDetail,
    isError: isCompanyUpdateError,
    isLoading: isCompanyUpdateProcessing,
    isSuccess: isCompanyUpdateSucceed,
    error: companyUpdateError
  } = useModifyCompanyMutation()

  const {t} = useTranslation()
  const {classes} = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const values = formMethods.watch()

  const {countriesIds, selectedCountry} = useCompanyFormData(values)

  const submitHandler = () => {
    if (readonlyMode) {
      setReadonlyMode(false)

      return
    }

    if (formMethods.formState.isValid) {
      updateCompanyDetail([
        'companies',
        'manage',
        {
          payload: {
            id: values.id,
            name: values.name,
            website: values.website,
            country: values.country,
            industry: values.industry
          } as Company
        }
      ])
    }
  }

  useEffect(() => {
    if (isCompanyUpdateError) {
      const error = companyUpdateError as AxiosError<CompanyErrorResponse>
      console.error('Error updating company details', error)
      handleCompanyValidationError(error.response?.data, formMethods)
      enqueueSnackbar(t('company.manage.cannotUpdateCompanyDetails'), {
        variant: 'error'
      })
    }

    if (isCompanyUpdateSucceed) {
      setReadonlyMode(true)
      enqueueSnackbar(t('company.manage.companyDetailsUpdated'), {
        variant: 'success'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanyUpdateError, isCompanyUpdateSucceed])

  return (
    <Paper elevation={4} data-test-id="manage-company-view-component">
      <Box className={isMobile ? classes.detailPageMobileStyle : classes.detailPageStyle}>
        <Grid container spacing={{md: 2}} columns={{xs: 1, sm: 1, md: 3}}>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            pr={2}
            key="company-detail-first-column"
            borderRight={isMobile ? '' : '1px solid lightgrey'}
          >
            <Typography className={classes.title} data-test-id="manage-company-detail-title">
              {t('company.manage.title')}
            </Typography>
            <FormTextInput
              readonlyMode={readonlyMode}
              formMethods={formMethods}
              isLoading={false}
              name="name"
              labelTranslationKey="companyForm.companyName"
              autoComplete="name"
              testIdPrefix="company-form"
              rules={{
                required: {value: true, message: 'COMPANY_NAME_EMPTY'} // Use the translation key directly of errorMessages, which is translated in `FormTextInput`
              }}
            />

            <CompanyCountryInput
              readonlyMode={readonlyMode}
              formMethods={formMethods}
              countriesIds={countriesIds}
              isLoading={false}
              selectedCountry={selectedCountry}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            pr={2}
            key="company-detail-second-column"
            borderRight={isMobile ? '' : '1px solid lightgrey'}
          >
            <div className={isMobile ? classes.websiteInputMobile : classes.websiteInput}>
              <FormTextInput
                readonlyMode={readonlyMode}
                formMethods={formMethods}
                isLoading={false}
                name="website"
                labelTranslationKey="companyForm.website"
                autoComplete="website"
                testIdPrefix="company-form"
              />
            </div>

            <div className={classes.industryInput}>
              <FormTextInput
                readonlyMode={readonlyMode}
                formMethods={formMethods}
                isLoading={false}
                name="industry"
                labelTranslationKey="companyForm.industry"
                autoComplete="industry"
                testIdPrefix="company-form"
              />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} key="company-detail-third-column">
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={readonlyMode ? 'outlined' : 'contained'}
                color={readonlyMode ? 'secondary' : 'primary'}
                startIcon={readonlyMode ? <EditIcon /> : <SaveIcon />}
                data-test-id="company-manage-edit-button"
                onClick={submitHandler}
                disabled={
                  isCompanyUpdateProcessing || (!readonlyMode && !formMethods.formState.isValid)
                }
              >
                {readonlyMode
                  ? t('company.manage.editCompanyProfileButton')
                  : t('company.manage.savCompanyProfileButton')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isCompanyUpdateProcessing && (
        <Box mb={1}>
          <LinearProgress variant="indeterminate" data-test-id="manage-company-processing" />
        </Box>
      )}
    </Paper>
  )
}
