import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React, {CSSProperties} from 'react'

type SectionHeaderProps = {
  title: string
  style?: CSSProperties
}
export const SectionHeader = ({title, style}: SectionHeaderProps) => (
  <Box style={style}>
    <Box pb={1}>
      <Typography style={{fontSize: '20px', fontWeight: 600}}>{title}</Typography>
    </Box>
  </Box>
)
