import {Button, ButtonProps, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  progressButtonWrapper: {
    display: 'inline-block',
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    bottom: theme.spacing(),
    left: '50%',
    marginLeft: -12
  }
}))

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  btnClassName?: string
  progressClassName?: string
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  children,
  className: classNameProp,
  btnClassName,
  startIcon,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.progressButtonWrapper, classNameProp)}>
      <Button
        disabled={loading || disabled}
        style={{
          background: loading ? '#91BBD9' : otherProps.style?.background
        }}
        startIcon={
          loading ? (
            <CircularProgress
              thickness={6.2}
              size={29}
              style={{
                marginLeft: '-10px',
                marginTop: '-3px',
                color: 'white',
                fontSize: '29px'
              }}
            />
          ) : (
            startIcon
          )
        }
        {...otherProps}
        className={btnClassName}
      >
        {children}
      </Button>
    </div>
  )
}

export default LoadingButton
