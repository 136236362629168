import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block'
  },
  quickPreviewTextField: {
    display: 'block',
    padding: '0 8px',
    borderRadius: '4px',
    border: '1px solid #D1D9E0',
    '& .MuiInputLabel-root': {
      padding: '4px 10px'
    }
  },
  helperText: {
    color: theme.palette.secondary.main
  }
}))
