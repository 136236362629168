import {Button, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const FONT_SIZE = 14

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: FONT_SIZE,
    lineHeight: 1
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))

interface IconTextButtonProps {
  IconComponent: React.ElementType
  label: string
  loading?: boolean
  onClick: () => void
  'data-test-id'?: string
  hide?: boolean
}

export const IconTextButton = ({
  IconComponent,
  label,
  loading,
  onClick,
  'data-test-id': dataTestId,
  hide = false
}: IconTextButtonProps) => {
  const classes = useStyles()

  if (hide) {
    return null
  }

  return (
    <Button
      variant="text"
      color="primary"
      onClick={onClick}
      disabled={loading}
      className={classes.button}
      data-test-id={dataTestId}
    >
      {loading ? (
        <CircularProgress size={FONT_SIZE} className={classes.icon} />
      ) : (
        <IconComponent className={classes.icon} />
      )}
      {label}
    </Button>
  )
}
