import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserAssignmentFormData} from './CompanyMemberAssociation.types'
import {useStyles} from './ManageCompanyMembers.styles'
import {UserAssignmentForm} from './UserAssignmentForm'

export const UserAssignmentDialog = ({
  open,
  handleClose,
  companyId
}: {
  open: boolean
  handleClose: () => void
  companyId: string
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const formMethods = useForm<UserAssignmentFormData>({
    defaultValues: {
      email: '',
      userId: '',
      companyId: companyId ?? ''
    },
    mode: 'all',
    reValidateMode: 'onChange'
  })
  return (
    <div className={classes.formCard}>
      <Dialog open={open} data-test-id="assign-member-dialog">
        <DialogTitle data-test-id="assign-user-dialog-title" className={classes.formHeader}>
          {t('company.member.assignDialog.title')}
        </DialogTitle>

        <DialogContent data-test-id="assign-member-dialog-content">
          <UserAssignmentForm handleOnCancel={handleClose} formMethods={formMethods} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
