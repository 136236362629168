import {Box, Paper} from '@mui/material'

import {FormTextInput} from '../../common/inputs/FormTextInput'

import {useStyles} from './CompanyForm.styles'
import {CompanyFormProps} from './CompanyForm.types'
import {useCompanyFormData} from './hooks/useCompanyFormData'
import {CompanyCountryInput} from './inputs/CompanyCountryInput'

export const CompanyForm = ({formMethods, readonlyMode, isLoading = false}: CompanyFormProps) => {
  const values = formMethods.watch()
  const {classes} = useStyles()
  const testIdPrefix = 'CompanyForm'

  const {countriesIds, selectedCountry} = useCompanyFormData(values)
  return (
    <Paper className={classes.parentPaper}>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className={classes.form}>
          <FormTextInput
            readonlyMode={readonlyMode}
            formMethods={formMethods}
            isLoading={isLoading}
            name="name"
            labelTranslationKey="companyForm.companyName"
            autoComplete="name"
            testIdPrefix={testIdPrefix}
          />
          <CompanyCountryInput
            readonlyMode={readonlyMode}
            formMethods={formMethods}
            countriesIds={countriesIds}
            isLoading={isLoading}
            selectedCountry={selectedCountry}
          />
          <FormTextInput
            readonlyMode={readonlyMode}
            formMethods={formMethods}
            isLoading={isLoading}
            name="website"
            labelTranslationKey="companyForm.website"
            autoComplete="website"
            testIdPrefix={testIdPrefix}
          />
          <FormTextInput
            readonlyMode={readonlyMode}
            formMethods={formMethods}
            isLoading={isLoading}
            name="industry"
            labelTranslationKey="companyForm.industry"
            autoComplete="industry"
            testIdPrefix={testIdPrefix}
          />
        </div>
      </Box>
    </Paper>
  )
}
