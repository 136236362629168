import {brandings} from '@hconnect/common/branding/Branding.utils'
import {isArray} from 'lodash'
export const useHeaderBrandings = (brandingId?: string | null) => {
  const brandingSplit = brandingId?.split('.')
  const strippedBrandingId = isArray(brandingSplit) ? brandingSplit[1] : brandingSplit
  const brandingName = Object.keys(brandings).find(
    (brandingName) => strippedBrandingId && brandingName.includes(strippedBrandingId)
  )

  const branding = brandingName ? brandings[brandingName] : undefined
  return {branding}
}
