import {useBreakPoints} from '@hconnect/uikit'
import {Snackbar} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button
} from '@mui/material'
import intlTelInput from 'intl-tel-input'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {useTfaSetup} from '../hooks/useTfaSetup'

import {AddTfaDialogContent} from './AddTfaDialogContent/AddTfaDialogContent'
import {DeleteTfaDialogContent} from './DeleteTfaDialogContent/DeleteTfaDialogContent'
import {EditTfaDialogContent} from './EditTfaDialogContent/EditTfaDialogContent'
import {useStyles} from './TfaDialog.styles'
import {TfaDialogSkeleton} from './TfaDialogSkeleton/TfaDialogSkeleton'

type TfaDialogProps = {
  setShowTfaDialog: (show: boolean) => void
  showTfaDialog: boolean
  tfaType: 'authenticatorApp' | 'phoneNumber' | 'none'
  mobileNumber?: string
  edit?: boolean
}

export enum TfaType {
  APP = 0,
  MOBILE = 1
}

export type DeleteConfirmationType = {
  tfaType: 'authenticatorApp' | 'phoneNumber'
  reason: 'delete' | 'replace'
}

export type PhoneDataType = {
  phoneNumber?: string
  countryData?: intlTelInput.CountryData
}

const steps = ['1', '2', '3', '4']
// eslint-disable-next-line complexity
export const TfaDialog = ({
  setShowTfaDialog,
  showTfaDialog,
  tfaType,
  mobileNumber,
  edit
}: TfaDialogProps) => {
  const {t} = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const {userId} = useParams<{userId: string}>()

  const canSetupAppTfa = userId === loggedInUserProfile?.user_id
  const {classes} = useStyles()

  const [activeTab, setActiveTab] = useState(canSetupAppTfa ? TfaType.APP : TfaType.MOBILE)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<
    DeleteConfirmationType | undefined
  >()
  const [editMode, setEditMode] = useState(edit)
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [phoneData, setPhoneData] = useState<PhoneDataType | undefined>({phoneNumber: mobileNumber})
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const {
    otpData,
    refetchOtpData,
    addPhoneNumber,
    verifyMobileCode,
    verifyAppCode,
    deleteTfa,
    isVerifyMobileCodeError,
    isVerifyAppCodeError,
    isOtpUriLoading,
    isPhoneNumberLoading,
    isVerifyMobileCodeLoading,
    isVerifyAppCodeLoading,
    isDeleteTfaLoading,
    isAddPhoneNumberError,
    recoveryCodesData
  } = useTfaSetup(
    userId || '',
    activeStep,
    setActiveStep,
    activeTab,
    setActiveTab,
    setErrorMessage,
    !!editMode,
    setEditMode,
    setShowDeleteConfirmation,
    showDeleteConfirmation
  )
  const [code, setCode] = useState('')
  const dialogRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    dialogRef.current?.focus()

    if (activeTab === TfaType.APP && activeStep === 1) {
      refetchOtpData()
    }

    if (activeTab === TfaType.MOBILE && activeStep === 1) {
      deleteTfa()
    }
  }, [activeStep])

  const handleSteps = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1)
    }

    if (activeStep === 1 && activeTab === TfaType.MOBILE) {
      addPhoneNumber({userIdentifier: userId || '', phoneNumber: phoneData?.phoneNumber || ''})
    }

    if (activeStep === 1 && activeTab === TfaType.APP) {
      setActiveStep(activeStep + 1)
    }

    if (activeStep === 2 && activeTab === TfaType.MOBILE) {
      verifyMobileCode({phoneNumber: phoneData?.phoneNumber || '', code})
    }

    if (activeStep === 2 && activeTab === TfaType.APP) {
      verifyAppCode({code})
    }

    if (activeStep === 3) {
      setShowTfaDialog(false)
    }
  }

  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text).then(() => setIsSnackbarOpen(true))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !editMode) {
      handleSteps()
    }
  }
  const formatHeight = () => {
    if (!editMode) {
      return activeStep === 0 || activeStep === 1 || (activeStep === 3 && recoveryCodesData)
        ? isMobile
          ? '800px'
          : '400px'
        : '300px'
    }

    if (editMode && !showDeleteConfirmation) {
      return '200px'
    }

    if (showDeleteConfirmation) {
      return '100px'
    }
  }

  return (
    <Dialog
      open={showTfaDialog}
      data-test-id="tfa-dialog"
      onKeyDown={handleKeyDown}
      ref={dialogRef}
      tabIndex={-1}
    >
      <DialogTitle>
        <Typography variant="h3" style={{color: '#54708C'}}>
          {t('userInformationForm.tfa.title')}
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: isMobile ? '100%' : '600px',
          transition: 'height 0.3s ease-in-out',
          height: formatHeight()
        }}
      >
        {isOtpUriLoading && <TfaDialogSkeleton />}
        {!isOtpUriLoading && (
          <>
            {editMode && !showDeleteConfirmation && (
              <EditTfaDialogContent
                tfaType={tfaType}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
                setEditMode={setEditMode}
              />
            )}
            {showDeleteConfirmation && (
              <DeleteTfaDialogContent showDeleteConfirmation={showDeleteConfirmation} />
            )}
            {!editMode && (
              <AddTfaDialogContent
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                activeStep={activeStep}
                steps={steps}
                otpData={otpData}
                isVerifyAppCodeError={isVerifyAppCodeError}
                isVerifyMobileCodeError={isVerifyMobileCodeError}
                errorMessage={errorMessage}
                setCode={setCode}
                isAddPhoneNumberError={isAddPhoneNumberError}
                recoveryCodesData={recoveryCodesData}
                canSetupAppTfa={canSetupAppTfa}
                phoneData={phoneData}
                setPhoneData={setPhoneData}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: activeStep === 3 && recoveryCodesData ? 'space-between' : 'flex-end'
        }}
      >
        {activeStep === 3 && recoveryCodesData && (
          <Button
            style={{
              boxShadow: 'none',
              outline: 'none',
              border: 'none',
              color: '#016AD4',
              backgroundColor: 'transparent',
              fontWeight: 500,
              alignSelf: 'start',
              padding: '16px 8px'
            }}
            data-test-id={'copy-recovery-codes'}
            variant="outlined"
            onClick={() => copyText(recoveryCodesData.toString())}
          >
            <ContentCopyIcon style={{color: '#016AD4', marginRight: '8px'}} />
            {t('userInformationForm.tfa.add.copyCodesToClipboard')}
          </Button>
        )}
        {activeStep + 1 !== steps.length && !editMode && !isOtpUriLoading && (
          <Button
            onClick={() =>
              activeStep + 1 < steps.length && activeStep > 0
                ? setActiveStep(activeStep - 1)
                : setShowTfaDialog(false)
            }
            variant="text"
            style={{color: '#016AD4', textTransform: 'none', marginRight: '16px'}}
          >
            {activeStep + 1 < steps.length && activeStep > 0
              ? t('userInformationForm.tfa.back')
              : t('userInformationForm.tfa.cancel')}
          </Button>
        )}
        {!editMode && !isOtpUriLoading && (
          <LoadingButton
            onClick={() => handleSteps()}
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: '#016AD4',
              textTransform: 'none',
              padding: '12px 16px'
            }}
            data-test-id={`step-button-${activeStep + 1}`}
            loading={isPhoneNumberLoading || isVerifyAppCodeLoading || isVerifyMobileCodeLoading}
            classes={{loadingIndicator: classes.loadingIndicator}}
          >
            {activeStep + 1 < steps.length
              ? t('userInformationForm.tfa.next')
              : t('userInformationForm.tfa.close')}
          </LoadingButton>
        )}
        {!showDeleteConfirmation && editMode && (
          <Button
            onClick={() => setShowTfaDialog(false)}
            variant="text"
            style={{color: '#016AD4', textTransform: 'none'}}
          >
            {t('userInformationForm.tfa.cancel')}
          </Button>
        )}
        {showDeleteConfirmation && (
          <Box>
            {!isDeleteTfaLoading && (
              <Button
                onClick={() => setShowDeleteConfirmation(undefined)}
                variant="text"
                style={{color: '#016AD4', textTransform: 'none', marginRight: '16px'}}
              >
                {t('userInformationForm.tfa.cancel')}
              </Button>
            )}
            <LoadingButton
              onClick={() => deleteTfa()}
              color="secondary"
              variant="contained"
              style={{
                backgroundColor: '#DA0901',
                textTransform: 'none',
                padding: '12px 16px'
              }}
              classes={{loadingIndicator: classes.loadingIndicator}}
              loading={isDeleteTfaLoading}
            >
              {showDeleteConfirmation.reason === 'delete'
                ? t('userInformationForm.tfa.deleteConfirmation')
                : t('userInformationForm.tfa.replaceConfirmation')}
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert onClose={() => setIsSnackbarOpen(false)} severity="success">
          {t('userInformationForm.tfa.add.copied')}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
