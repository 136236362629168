import React from 'react'
import {Route, Redirect, RouteProps, RouteComponentProps} from 'react-router-dom'

interface Props extends RouteProps {
  render?: (props: RouteComponentProps<any>) => React.ReactNode
  allowed: boolean
}

export const PrivateRoute = ({render, allowed, ...rest}: Props) => {
  return (
    <Route
      {...rest}
      render={(props) => (allowed && render ? render(props) : <Redirect to="/" />)}
    />
  )
}
