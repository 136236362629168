import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  trackSwitchBase: {
    height: 22,
    borderRadius: 20,
    backgroundColor: 'white!important'
  },
  enabledTrack: {
    backgroundColor: '#45A7F5!important'
  },
  disabledTrack: {
    backgroundColor: 'grey!important'
  },
  enabledSwitchBase: {
    top: 4,
    left: 4
  },
  disabledSwitchBase: {
    top: 4,
    left: 4
  },
  thumb: {
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
    width: 16,
    height: 16
  },
  switchRoot: {
    width: '68px',
    height: 'auto'
  }
}))
