import {trackEvent} from '@hconnect/common/logging/Analytics'
import {SelectGroupedOption} from '@hconnect/uikit'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {api} from '../../App.store'
import {prettyRoleName} from '../../layouts/Roles/utils'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {RoleType} from '../../modules/ManageUsers.selectors'

type RoleResponse = {type: RoleType; isInternal: boolean}

type QueryKey = [string, string, {userId: string | undefined | null}]
type QueryKey2 = [string, string, string]

export type DataScopeConfiguration = {
  dataScope: string
  isRequired: boolean
  multiSelect: boolean
}

export type RoleConfiguration = {
  roleType: RoleType
  dataScopes: DataScopeConfiguration[]
  isInternal: boolean
  autoGenerated: boolean
  expiryDate?: string
}

export type KeyToRoleConfiguration = {
  [roleType: string]: RoleConfiguration
}

const rolesConfigurationFetcher: QueryFunction<RoleResponse[]> = async ({queryKey}) => {
  const [base, endpoint, type] = queryKey as QueryKey2
  try {
    const response = await api.get<RoleResponse[]>(`${base}/${endpoint}/${type}`)
    return response.data
  } catch (e) {
    console.error(e)
    trackEvent('adminConsoleError', {
      product: 'adminConsole',
      date: new Date().toISOString(),
      errorCode: (e as AxiosError).response?.status,
      component: 'useRolesConfiguration',
      endpoint: `/${base}/${endpoint}`
    })
    return []
  }
}

const rolesConfigurationForUserIdFetcher: QueryFunction<RoleConfiguration[]> = async ({
  queryKey
}) => {
  const [base, endpoint, {userId}] = queryKey as QueryKey
  try {
    const response = await api.get<RoleConfiguration[]>(`${base}/${endpoint}?userId=${userId}`)
    return response.data
  } catch (e) {
    console.error(e)
    trackEvent('adminConsoleError', {
      product: 'adminConsole',
      date: new Date().toISOString(),
      errorCode: (e as AxiosError).response?.status,
      component: 'useRolesConfiguration',
      endpoint: `/${base}/${endpoint}`
    })
    return []
  }
}

const selectRoleOptions = (
  t: TFunction<'translation', undefined>,
  rolesByType?: KeyToRoleConfiguration
): SelectGroupedOption[] => {
  const sortedRolesByType = Object.values(rolesByType || {}).sort((a, b) =>
    a.roleType.localeCompare(b.roleType)
  )
  const internalRoles: RoleConfiguration[] = []
  const externalRoles: RoleConfiguration[] = []
  for (const role of sortedRolesByType) {
    if (role.isInternal) {
      internalRoles.push(role)
    } else {
      externalRoles.push(role)
    }
  }
  const options: SelectGroupedOption[] = []
  if (internalRoles.length) {
    options.push({label: t('roleAssignment.internal')})
  }

  for (const role of internalRoles) {
    options.push({
      label: t(`roleAssignment.roleTypes.${role.roleType}`, prettyRoleName(role.roleType)),
      subLabel: t(`roleAssignment.roleTypesSubtitles.${role.roleType}`, ''),
      value: role.roleType
    })
  }

  if (externalRoles.length) {
    options.push({label: t('roleAssignment.external')})
  }

  for (const role of externalRoles) {
    options.push({
      label: t(`roleAssignment.roleTypes.${role.roleType}`, prettyRoleName(role.roleType)),
      subLabel: t(`roleAssignment.roleTypesSubtitles.${role.roleType}`, ''),
      value: role.roleType
    })
  }

  return options
}

export const useRolesConfiguration = () =>
  useQuery<RoleResponse[], AxiosError, RoleResponse[], QueryKey2>(
    ['configuration', 'roles', 'list'],
    rolesConfigurationFetcher
  )

export const useRolesConfigurationForUserId = () => {
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const {t} = useTranslation()

  const {data: roles, isLoading} = useQuery<
    RoleConfiguration[],
    AxiosError,
    RoleConfiguration[],
    QueryKey
  >(
    ['configuration', 'roles', {userId: loggedInUserProfile?.user_id || ''}],
    rolesConfigurationForUserIdFetcher
  )

  const rolesByType: KeyToRoleConfiguration | undefined = roles?.reduce(
    (rolesById, role) => ({
      ...rolesById,
      [role.roleType]: role
    }),
    {}
  )

  const roleOptions = selectRoleOptions(t, rolesByType)
  return {roles, rolesByType, roleOptions, isLoading}
}
