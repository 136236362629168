import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  addSecurityMethod: {
    color: '#016AD4',
    fontWeight: 500,
    alignSelf: 'flex-start',
    padding: '8px 12px',
    fontSize: '14px'
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))
