import {StandardTextFieldProps, TextField, MenuItem} from '@material-ui/core'
import React, {useEffect} from 'react'

export type Option = {
  value: string
  label: string
}

interface Props extends StandardTextFieldProps {
  autoSelectSingleOption?: boolean
  options?: Option[]
  emptyItem?: boolean
  emptyItemLabel?: string
  setFieldValue: (field: string, value: string) => void
}

export const AutoSelectDropdown: React.FC<Props> = ({
  autoSelectSingleOption = true,
  options,
  emptyItem = false,
  emptyItemLabel,
  value,
  name,
  setFieldValue,
  children,
  ...rest
}) => {
  useEffect(() => {
    if (!autoSelectSingleOption) return

    if (options?.length !== 1 || value) return

    if (!name) return

    setFieldValue(name, options[0].value)
  }, [value, options, autoSelectSingleOption, name, setFieldValue])

  return (
    <TextField value={value} name={name} {...rest}>
      {children}
      {emptyItem && (
        <MenuItem value="">
          <em>{emptyItemLabel}</em>
        </MenuItem>
      )}
      {options?.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
