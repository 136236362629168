import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  textField: {
    display: 'block'
  },
  helperText: {
    color: theme.palette.secondary.main
  }
}))
