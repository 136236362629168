import {HttpError} from '@hconnect/common/types'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {api} from '../../../App.store'
import {DeleteConfirmationType, TfaType} from '../dialogs/TfaDialog'

type AddPhoneNumberParams = {
  userIdentifier: string
  phoneNumber: string
}

type VerifyPhoneParams = {
  phoneNumber: string
  code: string
}

type VerifyAppParams = {
  code: string
}
export const useTfaSetup = (
  userId: string,
  activeStep: number,
  setActiveStep: (step: number) => void,
  activeTab: TfaType,
  setActiveTab: (tab: number) => void,
  setErrorMessage: (msg?: string) => void,
  editMode: boolean,
  setEditMode: (editMode: boolean) => void,
  setShowDeleteConfirmation: (confirmation?: DeleteConfirmationType) => void,
  showDeleteConfirmation?: DeleteConfirmationType
) => {
  const queryClient = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const [shouldFetchRecoveryCodes, setShouldFetchRecoveryCodes] = useState(false)

  const {
    data: otpData,
    isInitialLoading: isOtpUriLoading,
    isError: isOtpUriError,
    refetch: refetchOtpData
  } = useQuery(
    ['tfa/tfaappuri'],
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.get(`tfa/tfaappdetails/${userId}`)

      return response.data
    },
    {
      enabled: !editMode && activeStep === 1 && activeTab === TfaType.APP,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onError: (error: AxiosError<HttpError>) => {
        if (error.response?.status === 403) {
          setActiveTab(TfaType.MOBILE)
          setErrorMessage('You can setup Authenticator App TFA only for currently logged in user.')
        }
      }
    }
  )

  const {data: recoveryCodesData, isInitialLoading: isRecoveryCodesDataLoading} = useQuery(
    ['tfa/recoveryCodes'],
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.get<string[]>(`tfa/recoverycodes/${userId}`)
      return response.data
    },
    {
      enabled: shouldFetchRecoveryCodes,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: async () => {
        await queryClient.invalidateQueries(['tfa'])
        setActiveStep(activeStep + 1)
      }
    }
  )

  const {
    mutate: addPhoneNumber,
    isLoading: isPhoneNumberLoading,
    isError: isAddPhoneNumberError
  } = useMutation(
    async (params?: AddPhoneNumberParams) => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.post('/tfa', {...params})

      return response.data
    },
    {
      onSuccess: () => {
        setActiveStep(activeStep + 1)
      },
      onError: (error: AxiosError<HttpError>) => {
        if (error.response?.data.type.includes('mandatoryValueMissing')) {
          setErrorMessage('Mobile number is required')
        } else if (error.response?.data.type.includes('phoneAssociatedWithDifferentAccount')) {
          setErrorMessage('This phone is already associated with different account.')
        } else {
          setErrorMessage('Something went wrong. Please try again.')
        }
      }
    }
  )

  const {
    mutate: verifyMobileCode,
    isError: isVerifyMobileCodeError,
    isLoading: isVerifyMobileCodeLoading
  } = useMutation(
    async (params?: VerifyPhoneParams) => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.put(`/tfa/${userId}`, {...params})

      return response.data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['tfa'])
        setActiveStep(activeStep + 1)
      },
      onError: (error: AxiosError<HttpError>) => {
        if (error.response?.data.type.includes('mandatoryValueMissing')) {
          setErrorMessage('Activation Code required')
        } else {
          setErrorMessage('Something went wrong. Please try again')
        }
      }
    }
  )

  const {
    mutate: verifyAppCode,
    isError: isVerifyAppCodeError,
    isLoading: isVerifyAppCodeLoading
  } = useMutation(
    async (params?: VerifyAppParams) => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.put(`/tfa/verifyapptfa/${userId}`, {...params})

      return response.data
    },
    {
      onSuccess: () => {
        setShouldFetchRecoveryCodes(true)
      },
      onError: (error: AxiosError<HttpError>) => {
        if (error.response?.data.type.includes('mandatoryValueMissing')) {
          setErrorMessage('Activation Code required')
        } else {
          setErrorMessage('Something went wrong. Please try again')
        }
      }
    }
  )

  const {mutate: deleteTfa, isLoading: isDeleteTfaLoading} = useMutation(
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const response: AxiosResponse = await api.delete(`/tfa/${userId}`)

      return response.data
    },
    {
      onSuccess: async () => {
        if (showDeleteConfirmation?.reason === 'replace') {
          setEditMode(false)
        }
        setShowDeleteConfirmation(undefined)
        await queryClient.invalidateQueries(['tfa'])
      },
      onError: () => {
        setShowDeleteConfirmation(undefined)
        enqueueSnackbar(t('userInformationForm.tfa.delete.error'), {
          anchorOrigin: {vertical: 'top', horizontal: 'center'},
          variant: 'error'
        })
      }
    }
  )

  return {
    otpData,
    isOtpUriLoading,
    addPhoneNumber,
    isPhoneNumberLoading,
    verifyMobileCode,
    verifyAppCode,
    isOtpUriError,
    deleteTfa,
    isVerifyAppCodeError,
    isVerifyMobileCodeError,
    isVerifyAppCodeLoading: isRecoveryCodesDataLoading || isVerifyAppCodeLoading,
    isVerifyMobileCodeLoading,
    isDeleteTfaLoading,
    isAddPhoneNumberError,
    recoveryCodesData,
    isRecoveryCodesDataLoading,
    refetchOtpData
  }
}
