import {Typography} from '@hconnect/uikit'
import {Box, ChipProps, CircularProgress, makeStyles} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlant} from '../../hooks/usePlant'

interface Props {
  plantId: string
  countryId: string
  error?: Error
}

const useStyles = makeStyles(() => ({
  plantNumber: {
    fontSize: '14px'
  }
}))

export const RolePlantLookupChip: FunctionComponent<Props & ChipProps> = ({plantId, countryId}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const plantQueryInfo = usePlant({plantId, countryId})

  if (plantQueryInfo.isLoading) {
    return <CircularProgress size="20" />
  }

  if (plantQueryInfo.isError) {
    return (
      <Box>
        <Typography color="textPrimary">{plantId}</Typography>
        <Typography color="textPrimary">Error: Could not load plant</Typography>
        <Typography color="textPrimary">{(plantQueryInfo.error as AxiosError).message}</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Typography color="textPrimary">{t('manageUser.plant')}</Typography>
      <Typography color="textPrimary" className={classes.plantNumber}>
        {plantQueryInfo.data?.plantNumber}
      </Typography>
      <Typography color="textPrimary">{plantQueryInfo.data?.plantName}</Typography>
    </Box>
  )
}
