import {Company} from '@hconnect/apiclient'
import {QueryFunction, useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {api} from '../App.store'

type UseCompanyDetailQueryKey = ['companies', 'manage', {companyId: string}]
type UseUpdateCompanyQueryKey = ['companies', 'manage', {payload: Company}]

const fetchCompanyById =
  (): QueryFunction<Company | undefined, UseCompanyDetailQueryKey> =>
  async ({queryKey}) => {
    const [, , {companyId}] = queryKey
    const response = await api.get<Company>(`/companies/${companyId}`)
    return response.data
  }

export const useFetchCompanyDetail = (companyId: string) => {
  return useQuery(['companies', 'manage', {companyId}], fetchCompanyById(), {
    retry: false
  })
}

const updateCompany = () => async (queryKey: UseUpdateCompanyQueryKey) => {
  const [, , {payload}] = queryKey
  const response = await api.put('/companies', payload)
  return response.data
}

export const useModifyCompanyMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(updateCompany(), {
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['companies', 'manage'])
    }
  })
}
