import {User} from '@hconnect/apiclient'
import {TextField} from '@material-ui/core'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AutoSelectDropdown} from '../../../common/AutoSelectDropdown'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'
import {BrandingForCountry, getBrandingNameFromBrandingId} from '../utils/utils'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserBrandingInputProps = UserInformationProps & {
  brandings: BrandingForCountry[]
  user?: User
}

export const UserBrandingInput = ({
  readonlyMode,
  formMethods,
  user,
  brandings,
  isLoading
}: UserBrandingInputProps) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const classes = useStyles()
  const values = formMethods.watch()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  const shouldPreselectBranding = brandings.length > 0 && brandings.length < 2
  const noBrandings = brandings.length === 0

  const handleBrandingsValue = () => {
    if (user?.defaultBranding && readonlyMode) {
      return getBrandingNameFromBrandingId(
        values.availableCountries || [],
        user.defaultBranding,
        user.country || undefined
      )
    } else if (shouldPreselectBranding) {
      return brandings[0].brandingName
    }

    return '-'
  }

  return (
    <>
      {(readonlyMode && user) || noBrandings ? (
        <TextField
          label={t('userInformationForm.branding')}
          value={handleBrandingsValue()}
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      ) : (
        <Controller
          name="defaultBranding"
          control={formMethods.control}
          render={({field}) => (
            <AutoSelectDropdown
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              style={{width: '450px'}}
              inputRef={field.ref}
              data-test-id={`userInformationForm-${field.name}`}
              fullWidth
              className={classes.textField}
              select={!shouldPreselectBranding || !noBrandings}
              error={touched.defaultBranding && !!errors.defaultBranding}
              disabled={!values.country}
              label={t('userInformationForm.branding')}
              emptyItem
              emptyItemLabel={t('userInformationForm.select')}
              options={brandings.map((branding) => ({
                value: branding.brandingId,
                label: branding.brandingName
              }))}
              setFieldValue={(_, value) =>
                formMethods.setValue(field.name, value, {shouldValidate: true})
              }
              SelectProps={{
                MenuProps: {
                  style: {
                    width: 200
                  }
                },
                ...(shouldPreselectBranding && {IconComponent: () => null})
              }}
              helperText={!values.country && t('userInformationForm.brandingHelperText')}
              margin="normal"
              InputProps={{
                readOnly: shouldPreselectBranding,
                disableUnderline: shouldPreselectBranding
              }}
            />
          )}
        />
      )}
    </>
  )
}
