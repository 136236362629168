import {QueryFunction, useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {api} from '../../../../App.store'

import {Quota} from './useQuotas'
import {Subscription} from './useSubscriptions'

export interface CompanySubscriptionPlan {
  companyId: string
  id?: string | undefined
  subscription?: Subscription | undefined
  quota?: Quota | undefined
}

type UseSubscriptionPlansQueryKey = ['companies', 'subscriptionPlans', {companyId: string}]
type UseSaveSubscriptionPlanQueryKey = [
  'companies',
  'subscriptionPlan',
  {companyId: string; subscriptionId: string; quotaId: string}
]
type UseCancelSubscriptionPlanQueryKey = [
  'companies',
  'cancelSubscriptionPlan',
  {subscriptionPlanId: string}
]

const fetchSubscriptionPlansByCompanyId =
  (): QueryFunction<CompanySubscriptionPlan[] | undefined, UseSubscriptionPlansQueryKey> =>
  async ({queryKey}) => {
    const [, , {companyId}] = queryKey
    const response = await api.get<CompanySubscriptionPlan[]>(
      `digital-subscriptions/subscriptionPlans?companyId=${companyId}`
    )
    return response.data
  }

export const useSubscriptionPlanByCompanyId = (companyId: string) => {
  return useQuery(
    ['companies', 'subscriptionPlans', {companyId}],
    fetchSubscriptionPlansByCompanyId(),
    {
      retry: false
    }
  )
}

const saveSubscriptionPlan = () => async (queryKey: UseSaveSubscriptionPlanQueryKey) => {
  const [, , {companyId, subscriptionId, quotaId}] = queryKey
  const response = await api.post('digital-subscriptions/subscriptionPlans', {
    companyId: companyId,
    subscriptionId: subscriptionId,
    quotaId: quotaId
  })
  return response.data
}

export const useSaveSubscriptionPlanMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(saveSubscriptionPlan(), {
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['companies', 'subscriptionPlans'])
    }
  })
}

const cancelSubscriptionPlan = () => async (queryKey: UseCancelSubscriptionPlanQueryKey) => {
  const [, , {subscriptionPlanId}] = queryKey
  const response = await api.delete(`digital-subscriptions/subscriptionPlans/${subscriptionPlanId}`)
  return response.data
}

export const useCancelSubscriptionPlanMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(cancelSubscriptionPlan(), {
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['companies', 'subscriptionPlans'])
    }
  })
}