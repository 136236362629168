import {CompanyMember} from '@hconnect/apiclient'
import {ResponsiveTable, useMobileBreakPoint} from '@hconnect/uikit'
import LinkIcon from '@mui/icons-material/Link'
import { Box, Button, Paper, Typography } from "@mui/material";
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AssociatedMemberColumns} from './AssociatedMemberColumns'
import {useStyles} from './ManageCompanyMembers.styles'
import {UserAssignmentDialog} from './UserAssignmentDialog'

const ManageCompanyMembers = ({
  members,
  companyId
}: {
  members: CompanyMember[]
  companyId: string
}) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const isMobile = useMobileBreakPoint()
  const {classes} = useStyles()

  return (
    <>
      <Paper
        elevation={4}
        className={isMobile ? classes.mainPageMobileStyle : classes.mainPageStyle}
        data-test-id="company-associate-users-component"
      >
        <Box
          style={{display: 'flex', justifyContent: 'space-between'}}
          data-test-id="company-associate-users-component-title"
        >
          <Typography className={classes.title}>
            {t('company.member.title')}
          </Typography>

          <Button
            color="secondary"
            startIcon={<LinkIcon />}
            data-test-id="associate-user-dialog-action"
            onClick={() => setOpen(true)}
          >
            {t('company.member.linkUserToCompanyButton')}
          </Button>
        </Box>

        <ResponsiveTable
          keyField="id"
          emptyMessage={t('company.member.noEntryInfo')}
          rows={members ?? []}
          columns={AssociatedMemberColumns(companyId)}
          onSort={() => null}
          stickyHeader={true}
          tableHeight="70vh"
          isMobile={isMobile}
        />
      </Paper>
      <UserAssignmentDialog open={open} handleClose={() => setOpen(false)} companyId={companyId} />
    </>
  )
}

export {ManageCompanyMembers}
