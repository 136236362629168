import {Feature} from '@hconnect/apiclient/src/response'
import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosInstance} from 'axios'

type QueryKey = [string, AxiosInstance]

const featuresCheckFetcher: QueryFunction<Feature[]> = async ({queryKey}) => {
  const [, api] = queryKey as QueryKey
  try {
    const response = await api.get<Feature[]>('/features')
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const useFeaturesCheckFetcher = (api: AxiosInstance) => {
  return useQuery<Feature[], AxiosError, Feature[], QueryKey>(
    ['allFeatures', api],
    featuresCheckFetcher,
    {
      refetchOnWindowFocus: true,
      retry: false
    }
  )
}
