import {Card, CardProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 360,
    width: 350,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
  }
}))

export const RoleCard: React.FunctionComponent<CardProps> = ({children, ...otherProps}) => {
  const classes = useStyles()
  return (
    <Card elevation={4} className={classes.card} {...otherProps}>
      {children}
    </Card>
  )
}
