import {Typography} from '@hconnect/uikit'
import {Box, ChipProps, CircularProgress, makeStyles} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {usePayer} from '../../hooks/usePayer'

interface Props {
  payerId: string
  error?: Error
}

const useStyles = makeStyles(() => ({
  payerNumber: {
    fontSize: '14px'
  }
}))

export const RolePayerLookupChip: FunctionComponent<Props & ChipProps> = ({payerId}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const payerQueryInfo = usePayer({payerId})
  const payer = payerQueryInfo.data

  if (payerQueryInfo.isLoading) {
    return <CircularProgress size="20" />
  }

  if (payerQueryInfo.isError) {
    return (
      <Box>
        <Typography color="textPrimary">{payerId}</Typography>
        <Typography color="textPrimary">Error: Could not load payer</Typography>
        <Typography color="textPrimary">{(payerQueryInfo.error as AxiosError).message}</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Typography color="textPrimary">{t('manageUser.payer')}</Typography>
      <Typography color="textPrimary" className={classes.payerNumber}>
        {payer?.payerNumber}
      </Typography>
      <Typography color="textPrimary">{payer?.payerName}</Typography>
    </Box>
  )
}
