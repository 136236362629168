import {Typography} from '@hconnect/uikit'
import {Box, ChipProps, makeStyles} from '@material-ui/core'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  commentCategory: string
  error?: Error
}

const useStyles = makeStyles(() => ({
  commentCategory: {
    fontSize: '14px'
  }
}))

export const RoleCommentCategoryLookupChip: FunctionComponent<Props & ChipProps> = ({
  commentCategory
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Box>
      <Typography color="textPrimary">{t('manageUser.commentCategory')}</Typography>
      <Typography color="textPrimary" className={classes.commentCategory}>
        {commentCategory}
      </Typography>
    </Box>
  )
}
