import {Typography} from '@hconnect/uikit'
import {Box, ChipProps, CircularProgress, makeStyles} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useHaulier} from '../../hooks/useHaulier'

interface Props {
  haulierId: string
  error?: Error
}

const useStyles = makeStyles(() => ({
  haulierNumber: {
    fontSize: '14px'
  },
  haulierName: {}
}))

export const RoleHaulierLookupChip: FunctionComponent<Props & ChipProps> = ({haulierId}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const haulierQueryInfo = useHaulier({haulierId})
  const haulier = haulierQueryInfo.data

  console.timeLog(haulierId, haulierQueryInfo)

  if (haulierQueryInfo.isLoading) {
    return <CircularProgress size="20" />
  }

  if (haulierQueryInfo.isError) {
    return (
      <Box>
        <Typography color="textPrimary" className={classes.haulierName}>
          {haulierId}
        </Typography>
        <Typography color="textPrimary">Error: Could not load haulier</Typography>
        <Typography color="textPrimary">
          {(haulierQueryInfo.error as AxiosError).message}
        </Typography>
      </Box>
    )
  }

  return (
    <div data-test-id="manageUser.haulier">
      <Typography color="textPrimary">{t('manageUser.haulier')}</Typography>
      <Typography color="textPrimary" className={classes.haulierNumber}>
        {haulier?.haulierNumber}
      </Typography>
      <Typography color="textPrimary" className={classes.haulierName}>
        {haulier?.haulierName}
      </Typography>
    </div>
  )
}
