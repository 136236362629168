import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

export const useDependencyFetcher = <T>(
  asyncFunction: (dependencies: string[]) => Promise<T>,
  queryKey: string,
  dependencies: string[]
) =>
  useQuery<T, AxiosError>([queryKey, ...dependencies], async () => {
    return asyncFunction(dependencies)
  })
