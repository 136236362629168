import {Button, CardContent} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AddCircle from '@material-ui/icons/AddCircle'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {RoleCard} from './RoleCard'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}))

interface Props {
  onClick: () => void
}

export const RoleAddPlaceholder: FunctionComponent<Props> = ({onClick}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <RoleCard>
      <CardContent className={classes.cardContent}>
        <Button variant="text" color="primary" onClick={onClick} data-test-id="button-add-role">
          <AddCircle className={classes.icon} />
          {t('roleAssignment.addAnotherRole')}
        </Button>
      </CardContent>
    </RoleCard>
  )
}
