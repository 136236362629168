import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import {
  RoleConfiguration,
  useRolesConfigurationForUserId
} from '../common/hooks/useRolesConfiguration'
import {RoleViewLayout} from '../layouts/Roles/RoleViewLayout'
import {getDataScopeWeight} from '../modules/DataScopeConfiguration'
import {selectLoggedInUserPermissions} from '../modules/LoggedInUser.selectors'
import {GroupedRoleAssignment, RoleAssignment} from '../modules/ManageUsers.selectors'

import {RoleEditContainer} from './RoleEditContainer'

interface RoleViewContainerProps {
  groupedRoleAssignment: GroupedRoleAssignment
  updateRoleAssignments: (groupedRoleAssignment: GroupedRoleAssignment) => Promise<void>
  getRoles: () => void
  roleAssignments: RoleAssignment[]
}

// Client-site workaround until we get orderWeight from the roleConfiguration (backend)
const sortDataScopes = (dataScopeA: string, dataScopeB: string) =>
  getDataScopeWeight(dataScopeA) - getDataScopeWeight(dataScopeB)

export const RoleViewContainer: React.FC<RoleViewContainerProps> = ({
  groupedRoleAssignment,
  updateRoleAssignments,
  getRoles,
  roleAssignments
}: RoleViewContainerProps) => {
  const permissions = useSelector(selectLoggedInUserPermissions)
  const [isEditing, setEditing] = useState(false)
  const handleUpdateRoleAssignments = async (groupedRoleAssignment: GroupedRoleAssignment) => {
    await updateRoleAssignments(groupedRoleAssignment)
    setEditing(false)
  }

  const {rolesByType} = useRolesConfigurationForUserId()

  const roleConfiguration: RoleConfiguration | undefined =
    rolesByType?.[groupedRoleAssignment.roleType]
  const isInternal =
    roleAssignments.find((item) => item.roleType === groupedRoleAssignment.roleType)?.isInternal ??
    false

  if (!permissions) {
    return null
  }

  const canEdit =
    !!roleConfiguration && Object.keys(groupedRoleAssignment.unsupportedDataScopes).length === 0

  if (isEditing) {
    return (
      <RoleEditContainer
        groupedRoleAssignment={groupedRoleAssignment}
        updateRoleAssignments={handleUpdateRoleAssignments}
        onDiscard={() => setEditing(false)}
      />
    )
  }

  const commonDataScopes = Object.keys(groupedRoleAssignment.commonDataScopes).sort(sortDataScopes)
  const unsupportedDataScopes = Object.keys(groupedRoleAssignment.unsupportedDataScopes).sort(
    sortDataScopes
  )

  return (
    <RoleViewLayout
      commonDataScopes={commonDataScopes}
      unsupportedDataScopes={unsupportedDataScopes}
      isInternal={isInternal}
      canEdit={canEdit}
      groupedRoleAssignment={groupedRoleAssignment}
      getRoles={getRoles}
      setEditing={setEditing}
      roleAssignments={roleAssignments}
    />
  )
}
