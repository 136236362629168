import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  detailPageStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '26px 36px',
    marginTop: theme.spacing(2),
    height: '256px',
    gap: theme.spacing(2)
  },

  detailPageMobileStyle: {
    padding: '4px 8px',
    marginTop: theme.spacing(2)
  },

  websiteInput: {
    marginTop: theme.spacing(5)
  },

  websiteInputMobile: {
    marginTop: theme.spacing(2)
  },

  industryInput: {
    marginTop: theme.spacing(3)
  },

  title: {
    color: 'var(--Light-text-low-contrast, #54708C)',
    fontFeatureSettings: "'clig' off, 'liga' off",

    /* Card Title */
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px' /* 120% */
  },

  subscriptionLabel: {
    color: 'var(--Light-text-low-contrast, #54708C)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px' /* 133.333% */
  },
  subscriptionValue: {
    color: 'var(--Light-text-high-contrast, #00274D)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px' /* 137.5% */
  }
}))
