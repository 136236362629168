import {Box, Skeleton} from '@mui/material'
import React from 'react'

type InputSkeletonProps = {
  single?: boolean
  firstRowWidth?: number
  firstRowheight?: number
  secondRowWidth?: number
  secondRowheight?: number
}
export const InputSkeleton = ({
  firstRowWidth,
  firstRowheight,
  secondRowheight,
  secondRowWidth,
  single = false
}: InputSkeletonProps) => {
  return (
    <Box style={{padding: '5px 0'}}>
      <Skeleton width={firstRowWidth || 250} height={firstRowheight || 30}></Skeleton>
      {!single && (
        <Skeleton width={secondRowWidth || 200} height={secondRowheight || 30}></Skeleton>
      )}
    </Box>
  )
}
