import {ResponsiveTable} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useUserMessageHistory} from '../hooks/useMessageHistory'

import {MessageHistoryColumns} from './MessageHistory.columns'
import {useStyles} from './MessageHistory.styles'

type MessageHistoryProps = {
  userId: string
}

export const MessageHistory: React.FC<MessageHistoryProps> = ({userId}: MessageHistoryProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const {data: messages, isLoading: isLoading, isError: isError} = useUserMessageHistory(userId)

  return (
    <Box style={{overflow: 'auto', width: '100%'}}>
      <Typography
        style={{marginTop: '16px', marginLeft: '15px', fontSize: '12px', whiteSpace: 'nowrap'}}
      >
        {t('userInformationForm.messagesHistory')}
      </Typography>
      <ResponsiveTable
        keyField="messageHistory"
        rows={messages || []}
        columns={MessageHistoryColumns()}
        loading={isLoading}
        emptyMessage={
          (isError || (messages && messages.length === 0)) &&
          t('userInformationForm.noMessagesFound')
        }
        onSort={() => null}
        stickyHeader={true}
        scrollbarsVisible={true}
        tableContainerClassName={classes.tableContainer}
      />
    </Box>
  )
}
