import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1)
  },
  form: {
    overflow: 'auto',
    flex: 2
  },
  productAndMessageDiv: {
    overflow: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0
  }
}))
