import {Modal} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {FC, ReactElement} from 'react'

export const isIE11 = !!/Trident\/7\./.exec(navigator.userAgent)

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const ie11Workaround = {
  marginTop: '50px',
  marginLeft: '50px'
}

const useStyles = makeStyles(() => ({
  modal: isIE11 ? ie11Workaround : flexCenter
}))

interface ModalWrapperProps {
  open: boolean
  children: ReactElement
}

export const ModalWrapper: FC<ModalWrapperProps> = ({open, children}) => {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      {children}
    </Modal>
  )
}
