import {Customer} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {Box, ChipProps, CircularProgress, makeStyles} from '@material-ui/core'
import {AxiosError} from 'axios'
import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useCustomer} from '../../hooks/useCustomer'

interface Props {
  customerId: string
  error?: Error
}

interface CustomerProps {
  customer?: Customer
  isCustomerLoading: boolean
  isError: boolean
  error: AxiosError
}

const useStyles = makeStyles(() => ({
  customerNumber: {
    fontSize: '14px'
  },
  customerName: {}
}))

export const RoleCustomerLookupChip: FunctionComponent<Props & ChipProps> = ({customerId}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const customerQueryInfo = useCustomer({customerId})
  const customer = customerQueryInfo.data

  if (customerQueryInfo.isLoading) {
    return <CircularProgress size="20" />
  }

  if (customerQueryInfo.isError) {
    return (
      <Box>
        <Typography color="textPrimary" className={classes.customerName}>
          {customerId}
        </Typography>
        <Typography color="textPrimary">{t('manageUser.customer.error')}</Typography>
        <Typography color="textPrimary">
          {(customerQueryInfo.error as AxiosError).message}
        </Typography>
      </Box>
    )
  }

  return (
    <div data-test-id="manageUser.customer">
      <Typography color="textPrimary">{t('manageUser.customer.title')}</Typography>
      <Typography color="textPrimary" className={classes.customerNumber}>
        {customer?.customerNumber}
      </Typography>
      <Typography color="textPrimary" className={classes.customerName}>
        {customer?.customerName}
      </Typography>
    </div>
  )
}

export const RoleCustomerChip = ({customer, isCustomerLoading, isError, error}: CustomerProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  if (isCustomerLoading) {
    return <CircularProgress size={20} />
  }

  if (isError) {
    return (
      <Box>
        <Typography color="textPrimary" className={classes.customerName}>
          {customer?.customerId}
        </Typography>
        <Typography color="textPrimary">{t('manageUser.customer.error')}</Typography>
        <Typography color="textPrimary">{error.message}</Typography>
      </Box>
    )
  }

  return (
    <div data-test-id="manageUser.customer">
      <Typography color="textPrimary">{t('manageUser.customer.title')}</Typography>
      <Typography color="textPrimary" className={classes.customerNumber}>
        {customer?.customerNumber}
      </Typography>
      <Typography color="textPrimary" className={classes.customerName}>
        {customer?.customerName}
      </Typography>
    </div>
  )
}
