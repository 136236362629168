import {isFeatureEnabled} from '@hconnect/apiclient'
import {TextField} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AutoSelectDropdown, Option} from '../../../common/AutoSelectDropdown'
import {useFeaturesCheckFetcher} from '../hooks/useFeaturesCheckFetcher'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {api} from './../../../App.store'
import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserMarketInputProps = UserInformationProps & {
  marketSelectValues: Option[]
  marketName?: string | null
}

export const UserMarketInput = ({
  readonlyMode,
  formMethods,
  marketName,
  marketSelectValues,
  isLoading
}: UserMarketInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const values = formMethods.watch()
  const errors = formMethods.formState.errors
  const touched = formMethods.formState.touchedFields
  const [shouldMarkAsMandatory, setShouldMarkAsMandatory] = useState(false)
  const {data: features} = useFeaturesCheckFetcher(api)

  const handleCheckFeatures = () => {
    const shouldMarketBeMandatory = isFeatureEnabled(
      features || [],
      {country: values.country},
      'MarketMandatory'
    )
    setShouldMarkAsMandatory(shouldMarketBeMandatory)
  }

  useEffect(() => {
    void handleCheckFeatures()
  }, [values.country, readonlyMode])

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <>
      {readonlyMode ? (
        <TextField
          label={t('userInformationForm.marketId')}
          value={marketName}
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      ) : (
        <Controller
          name="marketId"
          control={formMethods.control}
          render={({field}) => (
            <AutoSelectDropdown
              required={shouldMarkAsMandatory}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              inputRef={field.ref}
              data-test-id={`userInformationForm-${field.name}`}
              fullWidth
              className={classes.textField}
              select={!readonlyMode}
              error={touched.marketId && !!errors.marketId}
              disabled={!values.country || marketSelectValues.length === 0}
              label={t('userInformationForm.marketId')}
              options={marketSelectValues}
              emptyItem={!(values.country === 'US' || values.country === 'CA')}
              emptyItemLabel={t('userInformationForm.none')}
              setFieldValue={(_, value) =>
                formMethods.setValue(field.name, value, {shouldValidate: true})
              }
              style={{
                width: 450
              }}
              SelectProps={{
                MenuProps: {
                  style: {
                    width: 500
                  }
                }
              }}
              helperText={!values.country && t('userInformationForm.marketIdHelperText')}
              margin="normal"
              InputProps={{readOnly: readonlyMode, disableUnderline: readonlyMode}}
            />
          )}
        />
      )}
    </>
  )
}
