import {useBreakPoints} from '@hconnect/uikit'
import {Box, Skeleton} from '@mui/material'
import React from 'react'

export const TfaDialogSkeleton = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: isMobile ? '600px' : '400px'
      }}
    >
      <Skeleton height="130px" />
      <Skeleton height="60px" />
      <Skeleton height="300px" width="300px" style={{alignSelf: 'center'}} />
      <Skeleton height="40px" width={isMobile ? '300px' : '350px'} style={{alignSelf: 'center'}} />
      <Box style={{display: 'flex', alignSelf: 'end', gap: '12px'}}>
        <Skeleton height="80px" width="80px" />
        <Skeleton height="80px" width="80px" />
      </Box>
    </Box>
  )
}
