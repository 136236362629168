import {trackEvent} from '@hconnect/common/logging/Analytics'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'
import {RoleAssignment} from '../../modules/ManageUsers.selectors'

type QueryKey = [string, {userId: string | undefined | null}]

const userRolesFetcher: QueryFunction<RoleAssignment[] | null> = async ({queryKey}) => {
  const [base, {userId}] = queryKey as QueryKey

  if (!userId) return null

  const response = await api.get<RoleAssignment[]>(`/${base}`, {
    params: {
      userId
    }
  })

  return response.data
}

export const useUserRoles = (userId?: string | null) =>
  useQuery<RoleAssignment[] | null, AxiosError, RoleAssignment[] | null, QueryKey>(
    ['roles', {userId}],
    userRolesFetcher,
    {
      refetchOnWindowFocus: true,
      retry: false,
      onError: (error) => {
        console.error(error)
        trackEvent('adminConsoleError', {
          product: 'adminConsole',
          date: new Date().toISOString(),
          errorCode: error.response?.status,
          component: 'useUserRoles',
          endpoint: `/roles/${userId}`
        })
      }
    }
  )
