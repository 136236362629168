import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  form: {
    paddingBottom: theme.spacing(1)
  },
  parentPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))
