import {useSelector} from 'react-redux'

import {Permission} from '../../modules/LoggedInUser'
import {selectLoggedInUserPermissions} from '../../modules/LoggedInUser.selectors'

export const useAdminPilotUser = () => {
  const permissions = useSelector(selectLoggedInUserPermissions)
  return permissions.some(
    (permission: Permission) =>
      permission.permissionType === 'VIEW_USERS' &&
      (!!permission.dataScope.payerId ||
        !!permission.dataScope.payerIds ||
        !!permission.dataScope.customerId ||
        !!permission.dataScope.customerIds)
  )
}
