import {TFunction} from 'i18next'

export const handleNoSpecialCharacters = (value: string, t: TFunction) => {
  const notAllowedSpecialChars = ['<', '>', '"', '(', ')', '=', '%']

  return (
    !notAllowedSpecialChars.some((symbol) => value.includes(symbol)) ||
    t('userInformationForm.specialCharsNotAllowed')
  )
}
