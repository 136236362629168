import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  detailPageStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '26px 36px',
    marginTop: theme.spacing(2)
  },

  detailPageMobileStyle: {
    padding: '4px 8px',
    marginTop: theme.spacing(2)
  },

  title: {
    color: 'var(--Light-text-low-contrast, #54708C)',
    fontFeatureSettings: "'clig' off, 'liga' off",

    /* Card Title */
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px' /* 120% */
  }
}))
