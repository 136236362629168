import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Audit} from '../../../pages/ManageUser/hooks/useAuditInformation'

import {AuditInformationColumns} from './AuditInformation.columns'

type AuditInformationProps = {
  audit?: Audit[]
  isLoading: boolean
}

export const AuditInformation = ({audit, isLoading}: AuditInformationProps) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  return (
    <Box>
      <ResponsiveTable
        keyField="eventDateTime"
        emptyMessage={!isLoading && t('audit.noAuditInfo')}
        rows={audit ?? []}
        columns={AuditInformationColumns()}
        loading={isLoading}
        onSort={() => null}
        stickyHeader={true}
        tableHeight={'70vh'}
        isMobile={isMobile}
      />
    </Box>
  )
}
