import {Typography, Switch, Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {useStyles} from './BlocklistNotificationsInput.styles'
import {UserInformationProps} from './types'

type BlocklistNotificationsInputProps = UserInformationProps
export const BlocklistNotificationsInput = ({
  readonlyMode,
  formMethods,
  isLoading
}: BlocklistNotificationsInputProps) => {
  const {t} = useTranslation()

  const classes = useStyles()

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  return (
    <Box style={{marginTop: '20px'}}>
      <Typography variant="subtitle2" style={{color: '#54708C'}}>
        {t('userInformationForm.messages.title')}
      </Typography>

      <Controller
        name="blocklistNotifications"
        control={formMethods.control}
        disabled={readonlyMode}
        render={({field}) => (
          <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <Switch
              {...field}
              data-test-id="blocklistNotificationsSwitch"
              disabled={readonlyMode}
              disableRipple={true}
              classes={{
                root: classes.switchRoot,
                track: clsx({
                  [classes.trackSwitchBase]: true,
                  [classes.disabledTrack]: readonlyMode,
                  [classes.enabledTrack]: !readonlyMode
                }),
                thumb: classes.thumb,
                switchBase: clsx({
                  [classes.disabledSwitchBase]: readonlyMode,
                  [classes.enabledSwitchBase]: !readonlyMode
                })
              }}
              checked={field.value}
              inputProps={{'aria-label': 'secondary checkbox'}}
            />
            <Typography variant="subtitle2" style={{color: '#54708C'}}>
              {field.value
                ? t('userInformationForm.messages.blocklistNotificationsEnabled')
                : t('userInformationForm.messages.blocklistNotificationsDisabled')}
            </Typography>
          </div>
        )}
      />
    </Box>
  )
}
