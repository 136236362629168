import {Haulier, getHaulierByNumber, getHaulierById} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

type HaulierParam = {
  haulierId: string
}

type HaulierNumberParam = {
  haulierNumber: string
  countryId: string
  orgUnitId: string
  businessLine: string
}

const fetcher: QueryFunction<Haulier, [string, HaulierParam]> = ({
  queryKey
}: {
  queryKey: [string, HaulierParam]
}): Promise<Haulier> => {
  const [, {haulierId}] = queryKey

  return getHaulierById(api)(haulierId)
}

export const useHaulier = ({haulierId}: HaulierParam) => {
  return useQuery(['hauliers', {haulierId}], fetcher, {retry: false})
}

const fetchHaulierByNumber: QueryFunction<Haulier[], [string, HaulierNumberParam]> = ({
  queryKey
}: {
  queryKey: [string, HaulierNumberParam]
}): Promise<Haulier[]> => {
  const [, {haulierNumber, countryId, orgUnitId, businessLine}] = queryKey

  return getHaulierByNumber(api)(haulierNumber, {countryId, orgUnitId, businessLine})
}

export const useHaulierByNumber = (
  haulierNumber: string,
  countryId: string,
  orgUnitId: string,
  businessLine: string,
  enabled?: boolean
) => {
  return useQuery(
    ['hauliers', {haulierNumber, countryId, orgUnitId, businessLine}],
    fetchHaulierByNumber,
    {
      retry: false,
      enabled
    }
  )
}
