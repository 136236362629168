import {Box} from '@mui/material'
import React from 'react'

import {useStyles} from './StepIconComponent.styles'

export const StepIconComponent = (
  label: string,
  index: number,
  activeStep: number,
  steps: string[]
) => {
  const {classes} = useStyles()
  const formatStepColor = () => {
    if (activeStep + 1 < steps.length && activeStep === index) {
      return '#016AD4'
    }

    if (activeStep > index || activeStep + 1 === steps.length) {
      return '#00AB50'
    }

    return '#D9D9D9'
  }

  return (
    <Box
      className={classes.stepIconComponent}
      style={{
        backgroundColor: formatStepColor(),
        color: activeStep >= index ? '#FFFFFF' : '#000000'
      }}
    >
      {label}
    </Box>
  )
}
