import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  stepIconComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '54px',
    width: '54px',
    transition: 'background-color 2s ease-in-out',
    borderRadius: '50%',
    fontWeight: 600
  }
}))
