import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  tags: {
    borderRadius: '14px'
  },
  tagMod1: {
    color: '#FFFFFF',
    backgroundColor: '#0F89FF'
  },
  tagMod2: {
    color: '#00274D',
    backgroundColor: 'rgba(40, 77, 112, 0.15)'
  },
  columnLabel: {
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere'
  },
  singleColumnTag: {
    height: 'auto !important'
  }
}))
