import {useMobileBreakPoint, useTranslation} from '@hconnect/uikit'
import AddIcon from '@mui/icons-material/Add'
import {Paper, Typography, Box, Button, List} from '@mui/material'
import {useState} from 'react'

import {CompanySubscriptionsListItem} from './CompanySubscriptionsListItem'
import {useSubscriptionPlanByCompanyId} from './hooks/useCompanySubscriptions'
import {useStyles} from './ManageCompanySubscriptions.styles'

export const ManageCompanySubscriptions = ({companyId}: {
  companyId: string
}) => {
  const isMobile = useMobileBreakPoint()
  const {t} = useTranslation()
  const {classes} = useStyles()

  const [showAddSubscription, setShowAddSubscription] = useState(false)
  const {data: subscriptionPlans} = useSubscriptionPlanByCompanyId(companyId)

  const addHandler = () => {
    setShowAddSubscription(true)
  }
  
  return (
    <Paper
      elevation={4}
      className={isMobile ? classes.detailPageMobileStyle : classes.detailPageStyle}
      style={{flexDirection: 'column'}}
      data-test-id="company-subscriptions-component"
    >
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography className={classes.title} data-test-id="company-subscriptions-component-title">
          {t('company.manage.subscriptions.title')}
        </Typography>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          disabled={showAddSubscription || (subscriptionPlans && subscriptionPlans.length > 0)}
          onClick={addHandler}
          data-test-id="company-subscriptions-component-add-subscription"
        >
          {t('company.manage.subscriptions.addSubscription')}
        </Button>
      </Box>
      <List>
        {showAddSubscription && (
          <CompanySubscriptionsListItem
            key="new-company-subscription-id"
            isReadonly={false}
            data={{companyId: companyId}}
            cancelAddCallback={() => {
              setShowAddSubscription(false)
            }}
          />
        )}
        {subscriptionPlans?.map((subscription) => (
          <CompanySubscriptionsListItem
            key={subscription.id}
            data={subscription}
            isReadonly={true}
            cancelAddCallback={() => {}}
          />
        ))}
      </List>
      {(!subscriptionPlans || subscriptionPlans.length === 0) && !showAddSubscription && (
        <Typography align="center" variant="body2" data-test-id="company-subscriptions-component-no-subscriptions">
          {t('company.manage.subscriptions.noSubscriptions')}
        </Typography>
      )}
    </Paper>
  )
}
