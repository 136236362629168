import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../App.store'

export interface UserMessageHistory {
  id: number
  subject: string
  sentDate: string
  type: 'email' | 'sms'
  body: string
}

type MessageQueryKey = [string, string]

const userMessageHistoryFetcher: QueryFunction<UserMessageHistory[]> = async ({queryKey}) => {
  const [, userId] = queryKey as MessageQueryKey
  try {
    const response = await api.get<UserMessageHistory[]>(`notifications/message/${userId}`)
    if (response.status === 204) {
      return []
    }
    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      throw new Error('No user message history found!')
    }
    throw new Error('Error fetching user message history')
  }
}

export const useUserMessageHistory = (userId: string | number) => {
  return useQuery<UserMessageHistory[], MessageQueryKey>(
    ['userMessageHistory', userId],
    userMessageHistoryFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )
}
