import {QueryFunction, useQuery} from '@tanstack/react-query'

import {api} from '../../../../App.store'

export interface Quota {
  id: string
  limit: number
  type: string
}

type UseQuotasQueryKey = ['companies', 'quotas']

const fetchQuotas =
  (): QueryFunction<Quota[] | undefined, UseQuotasQueryKey> =>
  async ({queryKey}) => {
    const [,] = queryKey
    const response = await api.get<Quota[]>('/digital-subscriptions/quotas')
    return response.data
  }

export const useQuotas = () => {
  return useQuery(['companies', 'quotas'], fetchQuotas(), {
    retry: false
  })
}
