import {Country} from '@hconnect/apiclient'
import {useMemo} from 'react'

import {CompanyFormData} from '../CompanyForm.types'

export const useCompanyFormData = (values: CompanyFormData) => {
  const {availableCountries} = values

  const countriesIds = useMemo(
    () => availableCountries?.map((country: Country) => country.countryCode) || [],
    [availableCountries]
  )

  const selectedCountry = availableCountries?.find(
    (country) => country.countryCode === values.country
  )

  return {
    countriesIds,
    selectedCountry,
    availableCountries
  }
}
