import {LastActivityDate} from '@hconnect/apiclient'
import {ResponsiveTable} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './ProductActivity.styles'
import {ProductActivityColumns} from './ProductActvity.columns'

type ProductActivityProps = {
  lastActivityDates?: LastActivityDate[]
  isLoading: boolean
}
export const ProductActivity = ({lastActivityDates, isLoading}: ProductActivityProps) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  return (
    <Box
      style={{
        overflow: 'auto',
        width: '100%',
        marginTop: '12px'
      }}
    >
      <Typography
        style={{
          marginTop: '16px',
          marginLeft: '15px',
          fontSize: '12px',
          whiteSpace: 'nowrap'
        }}
      >
        {t('userInformationForm.productActivity')}
      </Typography>
      <ResponsiveTable
        keyField="lastActivityDate"
        emptyMessage={!isLoading && t('userInformationForm.noActivityFound')}
        rows={lastActivityDates ?? []}
        columns={ProductActivityColumns()}
        loading={isLoading}
        onSort={() => null}
        stickyHeader={true}
        scrollbarsVisible={true}
        tableContainerClassName={classes.tableContainer}
      />
    </Box>
  )
}
