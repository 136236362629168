import {useFeaturesCheckFetcher} from '@hconnect/common/components/FeaturesCheck'

import {api} from '../App.store'

export const useCustomerByNameFeature = () => {
  // const {data, isLoading} = useFeaturesCheckFetcher(api)
  const {isLoading} = useFeaturesCheckFetcher(api)

  const isCustomersByNameSearch = false
  // data?.find((feature) => feature.name === 'CustomersByName' && feature.enabled) ?? false

  return {
    isCustomersByNameSearch,
    isLoading
  }
}
