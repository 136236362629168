import {flagPathByCountryCode} from '@hconnect/uikit'
import {makeStyles, MenuItem, StandardTextFieldProps, TextField} from '@material-ui/core'
import React, {useEffect} from 'react'

import {Option} from '../../common/AutoSelectDropdown'

type CountriesDropdownProps = StandardTextFieldProps & {
  countriesList: Option[]
  defaultSelectTitle: string
  setFieldValue?: (field: string, value: string) => void
  autoSelectSingleOption?: boolean
}

export const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center'
  },
  menuItemSelected: {
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}))

export const CountriesDropdown = ({
  countriesList,
  defaultSelectTitle,
  autoSelectSingleOption = true,
  setFieldValue,
  ...otherProps
}: CountriesDropdownProps) => {
  useEffect(() => {
    if (!autoSelectSingleOption) return

    if (countriesList.length !== 1 || otherProps.value) return

    if (!otherProps.name) return

    setFieldValue && setFieldValue(otherProps.name, countriesList[0].value)
  }, [otherProps.value, countriesList, autoSelectSingleOption, otherProps.name, setFieldValue])
  const classes = useStyles()
  return (
    <TextField {...otherProps} className={classes.menuItemSelected}>
      <MenuItem value="">
        <em>{defaultSelectTitle}</em>
      </MenuItem>
      {countriesList?.map((country) => (
        <MenuItem value={country.value} key={country.value} className={classes.menuItem}>
          {country.value !== 'MOCK' && (
            <img
              src={flagPathByCountryCode(country.value)}
              alt={`${country.label}-flag`}
              style={{marginRight: '10px'}}
            />
          )}
          {country.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
