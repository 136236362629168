import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f6f7',
    height: '54px',
    fontSize: '12px',
    fontWeight: 700,
    padding: '6px 16px',
    color: '#54708c'
  },
  verticalAlign: {
    verticalAlign: 'top'
  }
}))
