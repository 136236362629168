import {Product} from '@hconnect/apiclient'
import {TableColumnType, useTranslation} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import moment from 'moment'
import React from 'react'

interface LastActivity {
  clientId: Product
  lastActivityDate: string
}

export const ProductActivityColumns = () => {
  const {t} = useTranslation()

  const rowsPool: {[key: string]: TableColumnType} = {
    clientId: {
      field: 'clientId',
      headerName: t('userInformationForm.productName'),
      headerAlign: 'left',
      renderCell: (productActivity: LastActivity) => <Box>{productActivity?.clientId}</Box>
    },
    lastActivityDate: {
      field: 'lastActivityDate',
      headerName: t('userInformationForm.activityDate'),
      headerAlign: 'left',
      renderCell: (productActivity: LastActivity) => (
        <Box>{moment(productActivity?.lastActivityDate).format('DD/MM/YYYY')}</Box>
      )
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.clientId,
    rowsPool.lastActivityDate
  ]
  return rowOrder(rowsPool)
}
