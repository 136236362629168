import {SelectDropdown, useTranslation} from '@hconnect/uikit'
import {Typography} from '@material-ui/core'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import {Box, Button, LinearProgress} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'

import {CompanySubscriptionListItemData} from './CompanySubscriptions.types'
import {
  useSaveSubscriptionPlanMutation,
  useCancelSubscriptionPlanMutation
} from './hooks/useCompanySubscriptions'
import {Quota, useQuotas} from './hooks/useQuotas'
import {Subscription, useSubscriptions} from './hooks/useSubscriptions'

export const CompanySubscriptionsListItem = ({
  isReadonly,
  data,
  cancelAddCallback
}: CompanySubscriptionListItemData) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()

  const {data: subscriptions} = useSubscriptions()
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>()

  const {data: quotas} = useQuotas()
  const [selectedQuota, setSelectedQuota] = useState<Quota>()

  const {
    mutate: saveSubscriptionPlanData,
    isError: isSaveSubscriptionPlanError,
    isLoading: isSaveSubscriptionPlanProcessing,
    isSuccess: isSaveSubscriptionPlanSucceed
  } = useSaveSubscriptionPlanMutation()

  useEffect(() => {
    if (isSaveSubscriptionPlanError) {
      enqueueSnackbar(t('company.manage.subscriptions.saveSubscriptionFailed'), {
        variant: 'error'
      })
    }

    if (isSaveSubscriptionPlanSucceed) {
      enqueueSnackbar(t('company.manage.subscriptions.saveSubscriptionSucceeded'), {
        variant: 'success'
      })
      cancelAddCallback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSubscriptionPlanError, isSaveSubscriptionPlanSucceed])

  const handleSave = () => {
    saveSubscriptionPlanData([
      'companies',
      'subscriptionPlan',
      {
        companyId: data?.companyId ?? '',
        subscriptionId: selectedSubscription?.id ?? '',
        quotaId: selectedQuota?.id ?? ''
      }
    ])
  }

  const {
    mutate: cancelSubscriptionPlanData,
    isError: isCancelSubscriptionPlanError,
    isLoading: isCancelSubscriptionPlanProcessing,
    isSuccess: isCancelSubscriptionPlanSucceed
  } = useCancelSubscriptionPlanMutation()

  useEffect(() => {
    if (isCancelSubscriptionPlanError) {
      enqueueSnackbar(t('company.manage.subscriptions.cancelSubscriptionFailed'), {
        variant: 'error'
      })
    }

    if (isCancelSubscriptionPlanSucceed) {
      enqueueSnackbar(t('company.manage.subscriptions.cancelSubscriptionSucceeded'), {
        variant: 'success'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelSubscriptionPlanError, isCancelSubscriptionPlanSucceed])

  const handleCancelSubscription = () => {
    cancelSubscriptionPlanData([
      'companies',
      'cancelSubscriptionPlan',
      {
        subscriptionPlanId: data?.id ?? ''
      }
    ])
  }

  return (
    <Box
      style={{
        marginBottom: '10px',
        padding: '12px',
        border: '1px solid lightgrey',
        borderRadius: 1
      }}
    >
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography
          color="textPrimary"
          noWrap
          variant="h6"
          data-test-id="company-subscription-name"
        >
          Datalink |{' '}
          {isReadonly
            ? data?.subscription?.name
            : selectedSubscription?.name ?? t('company.manage.subscriptions.noPlanSelected')}
        </Typography>
        {isReadonly ? (
          <Button
            variant="text"
            color="primary"
            startIcon={<HighlightOffIcon />}
            onClick={handleCancelSubscription}
            data-test-id="company-subscription-cancel-subscription"
          >
            {t('company.manage.subscriptions.cancelSubscription')}
          </Button>
        ) : (
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={!selectedSubscription || !selectedQuota}
              onClick={handleSave}
              data-test-id="company-subscription-save"
            >
              {t('company.manage.subscriptions.saveSubscription')}
            </Button>
            <Button
              style={{marginLeft: '8px'}}
              variant="contained"
              color="primary"
              startIcon={<HighlightOffIcon />}
              onClick={cancelAddCallback}
              data-test-id="company-subscription-cancel-save"
            >
              {t('company.manage.subscriptions.cancelAddSubscription')}
            </Button>
          </Box>
        )}
      </Box>
      <Box style={{display: 'flex', justifyContent: 'left', marginTop: '10px'}}>
        <SelectDropdown
          disabled={isReadonly}
          onLight={true}
          label={t('company.manage.subscriptions.subscriptionPlan')}
          showSearch={false}
          options={subscriptions}
          renderItem={(item: Subscription) => {
            return (
              <Typography color="textPrimary" noWrap>
                {item.name}
              </Typography>
            )
          }}
          onChange={(item: Subscription) => {
            setSelectedSubscription(item)
          }}
          selectedItem={
            isReadonly ? {name: data?.subscription?.name} : {name: selectedSubscription?.name ?? ''}
          }
          stringifyItem={(item) => `${item.name}`}
          data-test-id="company-subscription-plan"
        />
        <Box style={{marginLeft: '8px'}}>
          <SelectDropdown
            disabled={isReadonly}
            onLight={true}
            label={t('company.manage.subscriptions.subscriptionQuota')}
            showSearch={false}
            options={quotas}
            renderItem={(item: Quota) => {
              return (
                <Typography color="textPrimary" noWrap>
                  {item.limit} {item.type}
                </Typography>
              )
            }}
            onChange={(item: Quota) => {
              setSelectedQuota(item)
            }}
            selectedItem={
              isReadonly
                ? {limit: data?.quota?.limit, type: data?.quota?.type}
                : {limit: selectedQuota?.limit ?? '', type: selectedQuota?.type ?? ''}
            }
            stringifyItem={(item) => `${item.limit} ${item.type}`}
            data-test-id="company-subscription-quota"
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '10px',
            maxWidth: '350px',
            alignItems: 'center'
          }}
        >
          {(isReadonly || selectedSubscription) && <InfoOutlinedIcon color="secondary" />}
          <Typography
            color="secondary"
            variant="caption"
            style={{marginLeft: '5px'}}
            data-test-id="company-subscription-description"
          >
            {isReadonly ? data?.subscription?.description : selectedSubscription?.description}
          </Typography>
        </Box>
      </Box>
      {(isSaveSubscriptionPlanProcessing || isCancelSubscriptionPlanProcessing) && (
        <Box mt={1}>
          <LinearProgress variant="indeterminate" data-test-id="manage-company-processing" />
        </Box>
      )}
    </Box>
  )
}
